import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {ModalNotificationActions} from './ModalNotificationSlice';
import {Box, Center, HStack, Image, useTheme, VStack} from '@chakra-ui/react';
import {H2, TextG12, TextG14} from '../../components/typography';
import {BlueButton} from '../../components/button';
import Modal from '../../components/Modals/Modal';
import HighFive from '../../assets/images/high-five.png';
import Check from '../../assets/images/check.png';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import useBookmark from '../../hooks/useBookmark';
import useOfferModal from "../../hooks/useOfferModal";

const StyledHeaderIcon = styled(Box)`
  background: white;
  border: 2px solid #276acd;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
  position: absolute;
  top: -45px;
`;

interface ModalNotificationProps {
  placeData?: any;
  eventData?: any;
  image?: string;
  title?: string;
  checkinMessage?: string;
  points?: string;
  closeLabel?: string;
  subtitle?: string;
  ctaLabel?: string;
  ctaLink?: string;
}

// TODO pass modal sheet content from outside of this component.
const ModalNotification = ({
  placeData,
  eventData,
  image = HighFive,
  title = 'High Five!',
  checkinMessage,
  points,
  closeLabel = 'Close',
  subtitle,
  ctaLabel,
  ctaLink,
}: ModalNotificationProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const place = placeData ? JSON.parse(placeData) : '';
  const event = eventData ? JSON.parse(eventData) : '';
  const { handleOnBookmarkClick } = useBookmark(
    place,
    'place',
    true
  );
  const {showOfferModal} = useOfferModal();

  useEffect(() => {
    return () => {
      dispatch(ModalNotificationActions.popNotification());
    };
  }, []);

  useEffect(() => {
    if (!show) {
      dispatch(ModalNotificationActions.popNotification());
    }
  }, [show]);

  return (
    <Modal
      isCentered={true}
      closeOnOverlayClick={false}
      show={show}
      closeButton={false}
      header={
        <>
          <Center pb={12}>
            <StyledHeaderIcon>
              <Image src={image} />
            </StyledHeaderIcon>
          </Center>
          <Center>
            <H2>{title}</H2>
          </Center>
        </>
      }>
      <VStack pb={4} spacing={4}>
        <HStack spacing={1}>
          <Image src={Check} alt={'Success'} />
          <TextG12 fontWeight={500}>
            {checkinMessage ||
              `You've earned ${points?.toLocaleString()} points for this Check-in.`}
          </TextG12>
        </HStack>
        {subtitle && (
          <Center>
            <TextG12 textAlign={'center'} fontWeight={500} color={theme.colors.grey}>
              {subtitle}
            </TextG12>
          </Center>
        )}
        {ctaLabel ? (
          <Center w={'100%'}>
            <BlueButton
              onClick={(e) => {
                setShow(false);
                if(ctaLink === 'offer-redemption') {
                  showOfferModal({ offer: event.locked_offer });
                  return;
                }
                if(ctaLink === 'bookmark') {
                  handleOnBookmarkClick(e);
                  return;
                }
                if(ctaLink) {
                  navigate(ctaLink);
                }
              }}
              w={'100%'}>
              {ctaLabel}
            </BlueButton>
          </Center>
        ) : null}
        {closeLabel && (
          <TextG12 onClick={() => setShow(false)} textAlign={'center'} color={theme.colors.grey}>
            {closeLabel}
          </TextG12>
        )}
      </VStack>
    </Modal>
  );
};

export default ModalNotification;
