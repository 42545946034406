import {Box, Stack, useTheme} from '@chakra-ui/react';
import React, {useEffect, useMemo, useState} from 'react';
import {Place, Event, Post, Offer} from '../../types/acrticle';
import ItemCarousel from '../ItemCarousel';
import {TextA14, TextG12} from '../typography';
import {useNavigate, useSearchParams} from 'react-router-dom';
import PlaceCard from '../Articles/PlaceCard';
import EventCard from '../Articles/EventCard';
import PostCard from '../Articles/PostCard';
import {SkeletonLoader} from '../SkeletonLoader';
import useGetUserLocation from '../../hooks/useUserLocation';
import {usePlaceTypes} from '../../hooks/usePlaceTypes';
import {getAppName} from '../../utils/app';
import OfferCard from '../Articles/Offers/OfferCard';
import TutorialTooltips from '../TutorialTooltips';
import useScrollPosition from '../../hooks/useScrollPosition';
import useActiveRedemptions from '../../hooks/useActiveRedemptions';
import {useFetchOffer, useOffers} from '../../hooks/useOffers';
import {useEvents} from '../../hooks/useEvents';
import {useInfinitePlacesByType} from '../../hooks/useFetchPlacesByType';
import {filter, map, uniqBy} from 'lodash';
import {getArticleTopItems} from '../../utils/articleTopItems';
import HomeTooltips from '../TutorialTooltips/HomeTooltips';
import DiscoverMoreArrow from '../HomeContext/DiscoverMoreArrow';
import HomeQuickLinks from '../HomeQuickLinks/index';
import {useFeaturedContents} from '../../hooks/useFeaturedContents';

type FeaturedContent = Post | Place | Offer | Event;
import {useSelector} from 'react-redux';
import HomeCommunityButton from '../HomeCommunityButton';
import MembershipDashboard from '../MembershipDashboard';
import GuidedExperienceWidget from '../GuidedExperienceWidget/GuidedExperienceWidget';
import useAuth from '../../hooks/useAuth';
import GetMembership from '../GetMembership';
import RequiresEarlyAccess from '../RequiresEarlyAccess';
import MembershipBenefitsHomeButton from '../MembershipBenefitsHomeButton';

const HomeFeed = ({showLoader}: {showLoader?: boolean}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const userLocation = useGetUserLocation();
  const {user} = useAuth();
  const {placeTypesLoading, getIdsForCategory} = usePlaceTypes();
  const [searchParams] = useSearchParams();
  const paramOfferId = searchParams.get('offer');
  const [showTutorial, setShowTutorial] = useState(false);
  const [homeOffers, setHomeOffers] = useState<Array<any>>([]);
  const paramOffer = useFetchOffer(paramOfferId);
  const isTutorial = !!searchParams.get('tutorial');
  const selectedCommunityId = useSelector((state: any) => state.community.community)?.id;

  const featuredContentComponents = useMemo(() => {
    return {
      post: <PostCard />,
      event: <EventCard />,
      place: <PlaceCard />,
      offer: <OfferCard />,
    };
  }, []);

  const {activeRedemptions} = useActiveRedemptions();

  const {events, eventsIsLoading} = useEvents({communityId: selectedCommunityId?.toString()});

  const {offers, offersIsLoading} = useOffers({communityId: selectedCommunityId?.toString()});

  const {
    featuredContents,
    isFeaturedContentsLoading,
  }: {featuredContents: FeaturedContent[]; isFeaturedContentsLoading: boolean} =
      useFeaturedContents({communityId: selectedCommunityId?.toString()});

  useEffect(() => {
    if (offers) {
      const activeOffers = map(
          filter(activeRedemptions, (redemption) => !!redemption.offer.countdown_required),
          (redemption) => redemption.offer
      );
      setHomeOffers((prev) => {
        return uniqBy([...offers, ...activeOffers], 'id');
      });
    }
  }, [offers]);

  const foodPlaceIds: string[] = useMemo(() => {
    if (!placeTypesLoading) {
      return [
        ...getIdsForCategory('Places to eat'),
        ...getIdsForCategory('Bars'),
        ...getIdsForCategory('Coffee & Desserts'),
      ];
    } else return [];
  }, [placeTypesLoading]);

  const fetchFoodPlaces = useInfinitePlacesByType({
    lat: userLocation?.data?.latitude,
    long: userLocation?.data?.longitude,
    open: 'open',
    typeIds: foodPlaceIds,
    sort: 'ai',
    communityId: selectedCommunityId?.toString(),
    enabled: !placeTypesLoading,
  });
  const foodPlaces = fetchFoodPlaces.data?.pages[0]?.data?.data;

  const isLoading =
      eventsIsLoading ||
      offersIsLoading ||
      fetchFoodPlaces.isLoading ||
      isFeaturedContentsLoading ||
      placeTypesLoading ||
      showLoader;

  useScrollPosition(!isTutorial);

  useEffect(() => {
    if (!!searchParams.get('offer') && homeOffers.length > 0 && paramOffer) {
      const offer = paramOffer.data?.data;
      const offerId = offer.id;
      if (filter(homeOffers, {id: offerId}).length == 0 && offer) {
        setHomeOffers((prev) => {
          if (offer && filter(prev, {id: offer.id}).length == 0) return [offer, ...prev];
          return [...prev];
        });
      }
    }
  }, [searchParams.get('offer'), homeOffers, paramOffer]);

  useEffect(() => {
    setShowTutorial(isTutorial);

    if (isTutorial) window.scrollTo(0, 0);
  }, [searchParams]);

  return (
      <Box>
        <>
          {showTutorial && <TutorialTooltips tooltipFunc={HomeTooltips} />}

          <RequiresEarlyAccess>
            {user?.hasActiveMembership ? <MembershipDashboard /> : <GetMembership />}
            <GuidedExperienceWidget />
          </RequiresEarlyAccess>
          <Stack>
            {isFeaturedContentsLoading ? (
                <SkeletonLoader
                    cardHeight="252px"
                    w={user?.hasEarlyAccess ? '100%' : '100vw'}
                    left={user?.hasEarlyAccess ? 0 : -4}
                    cardBorderRadius={user?.hasEarlyAccess ? '12px' : 0}
                    pos="relative"
                    p={0}
                    mt={10}
                    mb="34px"
                    hasTitle={true}
                />
            ) : (
                featuredContents?.length && (
                    <ItemCarousel
                        title={user?.hasEarlyAccess ? 'See Our Top Picks' : undefined}
                        left={user?.hasEarlyAccess ? 0 : -4}
                        w={user?.hasEarlyAccess ? '100%' : '100vw'}
                        id="hero-tooltip"
                        highlightId="hero-tooltip"
                        mt={user?.hasEarlyAccess ? 6 : 10}
                        mb={2}
                        topRightItem={
                          <TextA14
                              id="more_events"
                              fontWeight="900"
                              onClick={() =>
                                  navigate(
                                      `/search/all?filterTitle=${encodeURIComponent('Our Top Picks')}&featured=1`
                                  )
                              }
                              color="#276ACD"
                              mt={user?.hasEarlyAccess ? 0 : '-20px'}>
                            More
                          </TextA14>
                        }
                        swiperProps={{
                          autoplay: true,
                          items: featuredContents?.slice(0, 5) || [],
                          render: (featuredContent) => {
                            const type = featuredContent?.model_type?.toLowerCase();
                            if (type) {
                              return React.cloneElement(featuredContentComponents[type], {
                                isFeatured: true,
                                borderRadius: user?.hasEarlyAccess ? '12px' : 0,
                                height: '252px',
                                [type]: featuredContent,
                                ...getArticleTopItems({
                                  ...featuredContent,
                                  type: featuredContent.type || type,
                                }),
                              });
                            }
                            return null;
                          },
                        }}
                    />
                )
            )}

            <HomeQuickLinks />
            {user?.hasEarlyAccess ? (
                user.hasActiveMembership ? (
                    <MembershipBenefitsHomeButton />
                ) : null
            ) : (
                <HomeCommunityButton />
            )}

            <DiscoverMoreArrow />

            {fetchFoodPlaces.isLoading ? (
                <SkeletonLoader mt="34px" cardBorderRadius="12px" />
            ) : (
                <ItemCarousel
                    id="feed-tooltip"
                    highlightId="feed-tooltip-highlight"
                    title="Explore Places Nearby"
                    topRightItem={
                      <TextA14
                          id="more_places"
                          fontWeight="900"
                          onClick={() =>
                              navigate(
                                  `/search/place?categories=${encodeURIComponent(
                                      foodPlaceIds.join(',')
                                  )}&sort=ai`
                              )
                          }
                          color="#276ACD">
                        More
                      </TextA14>
                    }
                    swiperProps={{
                      autoHeight: true,
                      items: foodPlaces,
                      render: (place) => (
                          <PlaceCard
                              isSlide
                              place={place as Place}
                              {...getArticleTopItems({...place, type: 'place'})}
                          />
                      ),
                    }}
                />
            )}
            {offersIsLoading ? (
                <SkeletonLoader mt="34px" cardBorderRadius="12px" />
            ) : (
                <ItemCarousel
                    id="feed2-tooltip"
                    highlightId="feed2-tooltip-highlight"
                    title="Get 'Locals Only' Perks"
                    topRightItem={
                      <TextA14
                          id="more_offers"
                          fontWeight="900"
                          onClick={() => navigate('/search/offer')}
                          color="#276ACD">
                        More
                      </TextA14>
                    }
                    swiperProps={{
                      autoHeight: true,
                      slidesPerGroup: 1,
                      slidesPerView: 1,
                      items: homeOffers,
                      render: (item) => {
                        return <OfferCard offer={item as Offer} />;
                      },
                    }}
                />
            )}

            {eventsIsLoading ? (
                <SkeletonLoader mt="34px" cardBorderRadius="12px" />
            ) : (
                <ItemCarousel
                    id="home-rsvp-tooltip"
                    highlightId="rsvp-tooltip-highlight"
                    title="Go To Events Nearby"
                    topRightItem={
                      <TextA14
                          id="more_events"
                          fontWeight="900"
                          onClick={() => navigate('/search/event')}
                          color="#276ACD">
                        More
                      </TextA14>
                    }
                    swiperProps={{
                      autoHeight: true,
                      items: events,
                      render: (event) => (
                          <EventCard
                              isSlide
                              event={event as Event}
                              {...getArticleTopItems({...event, type: 'event'})}
                          />
                      ),
                    }}
                />
            )}

            <Stack textAlign="center" px="15%">
              <TextG12 fontWeight="500">You're all caught up for now!</TextG12>
              <TextG12
                  color={theme.colors.grey}
                  onClick={() => navigate('/profile/lifestyle-goals-and-interests')}>
                Personalize your {getAppName()} experience by adding more interest tags.
                <i className="fi fi-rr-add" style={{position: 'relative', left: '5px', top: '2px'}} />
              </TextG12>
            </Stack>
          </Stack>
        </>
      </Box>
  );
};

export default HomeFeed;
