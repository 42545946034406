import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  chakra,
  Center,
  useTheme,
} from '@chakra-ui/react';
import React from 'react';
import {TextA12, TextA9, TextG10, TextG12} from '../../components/typography';
import CheckCircleFilled from '../../assets/icons/flaticons/check-circle-filled.svg';
import {getMembershipColor} from '../../utils/membership';

export const StyledCheckCircleFilled = chakra(CheckCircleFilled);

const MembershipBenefits = ({memberships, selectedMembershipId, setSelectedMembershipId}) => {
  const theme = useTheme();

  const essentialsSelected = selectedMembershipId == memberships[0]?.id;
  const lifestyleSelected = selectedMembershipId == memberships[1]?.id;

  const essientialColor = getMembershipColor(memberships[0]);
  const lifestyleColor = getMembershipColor(memberships[1]);

  const essentialBenefitCheck = (
    <Center>
      <StyledCheckCircleFilled
        transition="fill 0.3s ease"
        fill={essentialsSelected ? essientialColor : theme.colors.grey2}
        w="16px"
        h="16px"
      />
    </Center>
  );
  const lifestyleBenefitCheck = (
    <Center>
      <StyledCheckCircleFilled
        transition="fill 0.3s ease"
        fill={lifestyleSelected ? lifestyleColor : theme.colors.grey2}
        w="16px"
        h="16px"
      />
    </Center>
  );

  return (
    <TableContainer mt={6} px={5} maxW="100%" overflowY="auto">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th py={0} px="0px" pb="10px">
              <TextA12 textTransform="none" letterSpacing="-0.02em" fontWeight="700">
                Benefits
              </TextA12>
            </Th>
            <Th
              w="105px"
              maxW="105px"
              textAlign="center"
              py={0}
              px={0}
              pb="10px"
              onClick={() => setSelectedMembershipId(1)}>
              <TextA9
                textTransform="none"
                color={essentialsSelected ? essientialColor : theme.colors.grey2}
                letterSpacing="-0.02em"
                textAlign="center"
                transition="font-weight 0.3s ease, color 0.3s ease"
                fontWeight={essentialsSelected ? '700' : '400'}>
                {memberships[0]?.name}
              </TextA9>
            </Th>
            <Th
              w="105px"
              maxW="105px"
              textAlign="center"
              py={0}
              px={0}
              pb="10px"
              onClick={() => setSelectedMembershipId(2)}>
              <TextA9
                textTransform="none"
                color={lifestyleSelected ? lifestyleColor : theme.colors.grey2}
                letterSpacing="-0.02em"
                textAlign="center"
                transition="font-weight 0.3s ease, color 0.3s ease"
                fontWeight={lifestyleSelected ? '700' : '400'}>
                {memberships[1]?.name}
              </TextA9>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <BenefitRow
            title="Experience Pass / mo"
            essentialsSelected={essentialsSelected}
            lifestyleSelected={lifestyleSelected}
            essentialsColumn={
              <TextG12
                transition="font-weight 0.3s ease, color 0.3s ease"
                textAlign="center"
                color={essentialsSelected ? essientialColor : theme.colors.grey2}
                fontWeight="700">
                1
              </TextG12>
            }
            lifestyleColumn={
              <TextG12
                transition="font-weight 0.3s ease, color 0.3s ease"
                textAlign="center"
                color={lifestyleSelected ? lifestyleColor : theme.colors.grey2}
                fontWeight="700">
                2
              </TextG12>
            }
            setSelectedMembershipId={setSelectedMembershipId}
          />
          <BenefitRow
            title="Signature Pass / mo"
            essentialsSelected={essentialsSelected}
            lifestyleSelected={lifestyleSelected}
            essentialsColumn={
              <TextG12
                transition="font-weight 0.3s ease, color 0.3s ease"
                textAlign="center"
                color={theme.colors.grey2}
                fontWeight="700">
                -
              </TextG12>
            }
            lifestyleColumn={
              <TextG12
                transition="font-weight 0.3s ease, color 0.3s ease"
                textAlign="center"
                color={lifestyleSelected ? lifestyleColor : theme.colors.grey2}
                fontWeight="700">
                1
              </TextG12>
            }
            setSelectedMembershipId={setSelectedMembershipId}
          />
          <BenefitRow
            title="Event Discount"
            essentialsSelected={essentialsSelected}
            lifestyleSelected={lifestyleSelected}
            essentialsColumn={
              <TextG12
                transition="font-weight 0.3s ease, color 0.3s ease"
                textAlign="center"
                color={essentialsSelected ? essientialColor : theme.colors.grey2}
                fontWeight="700">
                {Number(memberships[0].event_discount).toFixed(0)}%
              </TextG12>
            }
            lifestyleColumn={
              <TextG12
                transition="font-weight 0.3s ease, color 0.3s ease"
                textAlign="center"
                color={lifestyleSelected ? lifestyleColor : theme.colors.grey2}
                fontWeight="700">
                {Number(memberships[1].event_discount).toFixed(0)}%
              </TextG12>
            }
            setSelectedMembershipId={setSelectedMembershipId}
          />
          {/* <BenefitRow
            title="Service Discount"
            essentialsSelected={essentialsSelected}
            lifestyleSelected={lifestyleSelected}
            essentialsColumn={
              <TextG12
                transition="font-weight 0.3s ease, color 0.3s ease"
                textAlign="center"
                color={essentialsSelected ? essientialColor : theme.colors.grey2}
                fontWeight="700">
                {Number(memberships[0].service_discount).toFixed(0)}%
              </TextG12>
            }
            lifestyleColumn={
              <TextG12
                transition="font-weight 0.3s ease, color 0.3s ease"
                textAlign="center"
                color={lifestyleSelected ? lifestyleColor : theme.colors.grey2}
                fontWeight="700">
                {Number(memberships[1].service_discount).toFixed(0)}%
              </TextG12>
            }
            setSelectedMembershipId={setSelectedMembershipId}
          /> */}
          <BenefitRow
            title="Locals-Only Discounts"
            essentialsSelected={essentialsSelected}
            lifestyleSelected={lifestyleSelected}
            essentialsColumn={essentialBenefitCheck}
            lifestyleColumn={lifestyleBenefitCheck}
            setSelectedMembershipId={setSelectedMembershipId}
          />
          <BenefitRow
            title="Secret Menu Items"
            essentialsSelected={essentialsSelected}
            lifestyleSelected={lifestyleSelected}
            essentialsColumn={essentialBenefitCheck}
            lifestyleColumn={lifestyleBenefitCheck}
            setSelectedMembershipId={setSelectedMembershipId}
          />
          <BenefitRow
            title="Premium Perks"
            essentialsSelected={essentialsSelected}
            lifestyleSelected={lifestyleSelected}
            essentialsColumn={essentialBenefitCheck}
            lifestyleColumn={lifestyleBenefitCheck}
            setSelectedMembershipId={setSelectedMembershipId}
          />
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default MembershipBenefits;

const BenefitRow = ({
  title,
  essentialsSelected,
  lifestyleSelected,
  essentialsColumn,
  lifestyleColumn,
  setSelectedMembershipId,
}) => {
  return (
    <Tr>
      <Td pl="0px" pr={1} borderBottom="none">
        <TextG10 fontSize="11px" letterSpacing="-0.02em" fontWeight="500">
          {title}
        </TextG10>
      </Td>
      <Td
        onClick={() => setSelectedMembershipId(1)}
        transition="background 0.3s ease, width 0.3s ease"
        px="10px"
        borderBottom="none"
        background={essentialsSelected ? '#DEF3F8' : 'transparent'}>
        {essentialsColumn}
      </Td>
      <Td
        onClick={() => setSelectedMembershipId(2)}
        transition="background 0.3s ease, width 0.3s ease"
        px="10px"
        borderBottom="none"
        background={lifestyleSelected ? '#7D3EEE1A' : 'transparent'}>
        {lifestyleColumn}
      </Td>
    </Tr>
  );
};
