import React from 'react';
import {checkInToPlace, checkInToEvent} from '../endpoints/api';
import {generatePath, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {ModalNotificationActions} from '../state/ModalNotification/ModalNotificationSlice';
import {ROUTES} from '../constants/routes';
import {useDispatch} from 'react-redux';
import useAuth from './useAuth';
import {ToastMessage} from '../components/toast';
import {Place, Event, Offer} from '../types/acrticle';
import {useQueryClient} from '@tanstack/react-query';
import {ActiveRedemption} from './useActiveRedemptions';
import {find} from 'lodash';
import {TextG12} from "../components/typography";
import {useTheme} from "@chakra-ui/react";

const invalidateOffers = (queryClient) => {
  queryClient.invalidateQueries([`offers`]);
  queryClient.invalidateQueries([`infinite-offers`]);
};

const invalidateEvent = (queryClient, eventId) => {
  queryClient.invalidateQueries(['events']);
  queryClient.invalidateQueries(['event', eventId]);
  queryClient.invalidateQueries(['infinite-events']);
};

type PlaceCheckInParams = {
  place: Place;
  latitude: number | undefined;
  longitude: number | undefined;
  isPublic: boolean;
  offerId?: number;
  offers: Array<Offer>;
};

type EventCheckInParams = {
  event: Event;
  eventOffers: Array<Offer>;
  latitude: number | undefined;
  longitude: number | undefined;
  isPublic: boolean;
  activeRedemptions: Array<ActiveRedemption>;
};

/**
 * This is the API provided by useCheckIn()
 */
interface CheckInAPI {
  placeCheckIn(params: PlaceCheckInParams): void;
  eventCheckIn(params: EventCheckInParams): void;
}

const CheckInContext = React.createContext<CheckInAPI>({} as CheckInAPI);

export const CheckInProvider = ({children}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const {reFetchUserData} = useAuth();
  const {pathname} = useLocation();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const placeCheckIn = ({
    place,
    latitude,
    longitude,
    isPublic,
    offerId,
    offers,
  }: PlaceCheckInParams): void => {
    checkInToPlace({placeId: place.id, latitude, longitude: longitude, isPublic, offerId})
      .then(({ data }) => {
        invalidateOffers(queryClient);
        // Re-fetch user data in order to get new value of points total.
        reFetchUserData();
        let subtitle;
        let cta;
        let closeLabel;

        if (offers.length > 0) {
          const url = new URL(location.href);
          if (!url.searchParams.get('tab')) url.searchParams.append('tab', 'Offers');
          subtitle =
            <>
              This place has&nbsp;
              <TextG12 as="span" userSelect="none" fontWeight="500" color={theme.colors.rose}>
                {offers.length} active Offers
              </TextG12>
              &nbsp;you can redeem with points.
            </>;
          cta = {
            ctaLabel: 'View Offers',
            ctaLink: url.pathname + url.search,
          };
          closeLabel = 'Skip for now';
        }
        else {
          subtitle = place.is_bookmarked
            ? 'This place is already in your bookmarks.'
            : 'Bookmark this place to stay up on special offers, upcoming events, and unique experiences.'
          cta = {
            ctaLabel: !place.is_bookmarked ? 'Bookmark' : 'Ok',
            ctaLink: !place.is_bookmarked ? 'bookmark' : null,
          };
          closeLabel = !place.is_bookmarked ? 'Skip for now' : null;
        }

        dispatch(
          ModalNotificationActions.pushNotification({
            placeData: JSON.stringify(place),
            points: data?.points_given,
            subtitle,
            closeLabel,
            ...cta,
          })
        );
        if (searchParams.has('id')) {
          navigate(`${pathname}?id=${searchParams.get('id')}`, {replace: true});
          return;
        }
        navigate(generatePath(ROUTES.PLACE, {placeId: place.id.toString()}), {replace: true});
      })
      .catch((e) => {
        ToastMessage({
          status: 'error',
          text: e.message,
        });
      });
  };

  const eventCheckIn = ({
    event,
    eventOffers,
    activeRedemptions,
    latitude,
    longitude,
    isPublic,
  }: EventCheckInParams): void => {
    checkInToEvent({eventId: event.id, latitude, longitude: longitude, isPublic})
      .then(({data}) => {
        invalidateOffers(queryClient);
        invalidateEvent(queryClient, event.id);
        // Re-fetch user data in order to get new value of points total.
        reFetchUserData();
        let title;
        let checkinMessage;
        let subtitle;
        let ctaLabel;
        let ctaLink;
        let closeLabel;

        const hasActive = find(activeRedemptions, (redemption) => {
          return redemption.offer.event?.id == event.id;
        });

        const unlocksOffer = event.unlocks_offer_id

        if(unlocksOffer) {
          title = 'Congrats!';
          checkinMessage = 'You’ve unlocked a special offer with this check-in.';
          subtitle = 'Lucky you! This offer is only valid during this event, so get it now before it\'s gone.';
          ctaLabel = 'Get Offer';
          ctaLink = 'offer-redemption';
          closeLabel = 'Close';
        } else if (hasActive) {
          title = data.points_given > 0 ? 'Woo hoo!' : 'Excellent';
          subtitle = 'You have an offer in your wallet that you can use right now. ';
          ctaLabel = 'View Offer';
          ctaLink = '/wallet';
        } else if (eventOffers.length <= 0 && data.points_given > 0) {
          title = data.points_given > 0 ? 'Woo hoo!' : 'Excellent';
          subtitle = 'Check out Perks to see how you can use these points.';
          ctaLabel = 'View Perks';
          ctaLink = '/search/offer';
        } else if (eventOffers.length > 0) {
          const url = new URL(location.href);
          if (!url.searchParams.get('highlight')) url.searchParams.append('highlight', 'offers');
          title = data.points_given > 0 ? 'Woo hoo!' : 'Excellent';
          subtitle = 'There are cool offers on this event that you can use right now.';
          ctaLabel = 'View Offers';
          ctaLink = url.pathname + url.search;
        }

        if(!checkinMessage) {
          checkinMessage =
            eventOffers.length > 0
              ? `You're checked-in to this event.`
              : data?.points_given > 0
                ? `You've earned ${data.points_given} points with this check-in.`
                : `You're now checked-in and ready to roll.`;
        }

        closeLabel = closeLabel || (data.points_given > 0 ? 'Close' : 'OK');

        dispatch(
          ModalNotificationActions.pushNotification({
            eventData: JSON.stringify(event),
            title,
            checkinMessage,
            points: data.points_given,
            subtitle,
            ctaLabel,
            ctaLink,
            closeLabel,
          })
        );
        // if (searchParams.has('id')) {
        //   navigate(`${pathname}?id=${searchParams.get('id')}`, {replace: true});
        //   return;
        // }
        // //navigate(generatePath(ROUTES.PLACE, {placeId: place.id.toString()}), {replace: true});
      })
      .catch((e) => {
        ToastMessage({
          status: 'error',
          text: e.message,
        });
      });
  };

  const checkInValue: CheckInAPI = {
    placeCheckIn,
    eventCheckIn,
  };

  return <CheckInContext.Provider value={checkInValue}>{children}</CheckInContext.Provider>;
};

export default function useCheckIn() {
  return React.useContext(CheckInContext);
}
