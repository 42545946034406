import {Flex, Image, useTheme, Center, Spinner, useMediaQuery} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useFetchPublicEvent} from '../../hooks/useEvent';
import FallbackImg from '../../assets/images/fallback.jpg';
import {Event} from '../../types/acrticle';
import DefaultEventImage from '../../assets/images/default_event.png';
import PreviewHeader from './PreviewHeader';
import PreviewAbout from './PreviewAbout';
import useAuth from '../../hooks/useAuth';
import {isMobile} from '../../utils/deviceCheck';
import BackButton from '../../components/BackButton';

const EventPreview = () => {
  const theme = useTheme();
  const {eventId} = useParams();
  const {user} = useAuth();
  const navigate = useNavigate();
  const fetchEvent = useFetchPublicEvent(Number(eventId));
  const event: Event = fetchEvent.data?.data;

  const isLoading = fetchEvent.isLoading;

  useEffect(() => {
    if (user && event && window.self == window.top) {
      isMobile
        ? navigate(`/event/${event.id}`, {replace: true})
        : navigate('/download', {replace: true});
    }
  }, [user, event]);

  if (isLoading || event === undefined)
    return (
      <Center minH="100vh">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );

  return (
    <>
      <Flex position="relative" height="250px">
        {window.self !== window.top && <BackButton onClick={() => navigate(-1)} m={'.75rem'} pos="relative" />}
        <Image
          fallbackStrategy="onError"
          fallbackSrc={FallbackImg}
          src={event?.image_url || DefaultEventImage}
          alt="cover image"
          position="absolute"
          width="100%"
          height="100%"
          objectFit="cover"
          zIndex="-1"
          borderBottomRadius="14px"
        />
      </Flex>
      <PreviewHeader event={event} />
      <PreviewAbout event={event} />
    </>
  );
};

export default EventPreview;
