import {VStack, Image, Flex, Center, Spinner, useTheme, AbsoluteCenter} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useFetchEvent} from '../../hooks/useEvent';
import Header from './Header';
import TicketDetailsContainer from './TicketDetailsContainer';
import TicketQuantityContainer from './TicketQuantityContainer';
import WillCallSelect from './WillCallSelect';
import Background from '../../assets/images/ticketBG.png';
import {BlueButton} from '../../components/button';
import {Event} from '../../types/acrticle';
import {TextA18, TextG12} from '../../components/typography';
import {TICKET_TYPES} from '../../types/ticket_types';
import useAuth from '../../hooks/useAuth';
import {useFetchActivePasses} from '../../hooks/useUserPasses';
import {filter, some} from 'lodash';

const TicketSelect = () => {
  const navigate = useNavigate();
  const {eventId} = useParams();
  const fetchEvent = useFetchEvent(Number(eventId));
  const event: Event = fetchEvent.data?.data;
  const [quantity, setQuantity] = useState(1);
  const isLoading = fetchEvent.isLoading;
  const [ticket, setTicket] = useState<any>();
  const theme = useTheme();
  const {user} = useAuth();

  const fetchActivePasses = useFetchActivePasses();
  const userPasses = fetchActivePasses.data?.data;

  useEffect(() => {
    if (event && event?.available_tickets && event?.available_tickets[0]) {
      setTicket({...event.available_tickets[0]});
    }
  }, [event, event?.available_tickets]);

  const eligibleUserPasses = filter(userPasses, (userPass) =>
    some(event?.pass_types, (passType) => passType.id === userPass?.pass?.type?.id)
  );

  if (isLoading)
    return (
      <Center style={{minHeight: '100vh'}}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );

  return (
    <VStack overflow="hidden" maxH="100%">
      <Header event={event} />
      {!ticket ? (
        <AbsoluteCenter h="30vh" px={12}>
          <TextA18 textAlign="center">No more tickets are available for this event!</TextA18>
        </AbsoluteCenter>
      ) : (
        <>
          <TicketDetailsContainer event={event} ticket={ticket} />
          <TicketQuantityContainer ticket={ticket} quantity={quantity} setQuantity={setQuantity} />
          {event.ticket_type_id == TICKET_TYPES.WILL_CALL ? <WillCallSelect /> : null}
        </>
      )}
      {user?.hasActiveMembership && eligibleUserPasses && eligibleUserPasses.length > 0 && (
        <Flex alignItems="baseline">
          <i
            className="fi fi-br-check"
            style={{fontSize: '10px', color: '#329241', marginRight: '0.3rem'}}></i>
          <TextG12 fontWeight="700" color="#329241">
            You can use a Pass during Checkout
          </TextG12>
        </Flex>
      )}
      <Image pos="absolute" bottom="230px" zIndex={-1} w="100%" src={Background} />
      <Flex
        background="white"
        pos="fixed"
        bottom="0px"
        justify="center"
        margin="0px !important"
        px={4}
        py={6}
        w="100%">
        <BlueButton
          w="100%"
          onClick={() => navigate(`/checkout?qty=${quantity}&event=${event.id}`, {replace: true})}>
          Continue to Checkout
        </BlueButton>
      </Flex>
    </VStack>
  );
};

export default TicketSelect;
