import {AbsoluteCenter, Box, Center, Flex, HStack, Image, Spacer, useTheme} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import Icon from '../../../components/Icon';
import {TextA18, TextG10, TextG12} from '../../../components/typography';
import useAuth from '../../../hooks/useAuth';
import {useFetchPlace} from '../../../hooks/useFetchPlace';
import {TICKET_TYPES} from '../../../types/ticket_types';
import {getTicketDate} from '../../../utils/date';

const dashedLine = (
  <hr
    style={{
      margin: '10px 5px',
      border: 'none',
      borderTop: '1px dashed #B1BBBB',
      height: '0',
    }}
  />
);

const TicketDetails = ({ticket, ticketNum, totalTickets}) => {
  const {data, isLoading} = useFetchPlace({
    id: Number(ticket.event.place_id),
  });
  const place = data?.data;
  const {user} = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const event = ticket.event;

  const ticketTypeId = event?.ticket_type_id;

  return (
    <Box
      borderRadius="12px"
      py={2}
      px={3}
      mx={4}
      my={10}
      boxShadow="0px 0px 30px 0px rgba(0, 0, 0, 0.18)">
      <Flex flexDir="column" gap="8px">
        <Box position="relative">
          <Image
            objectFit="cover"
            borderRadius="10px"
            src={ticket.event?.image_url}
            h="150px"
            w="100%"
          />
          {(ticketTypeId == TICKET_TYPES.WILL_CALL || ticketTypeId == TICKET_TYPES.DIGITAL) && (
            <Box
              background="#00000061"
              borderRadius="10px"
              pos="absolute"
              top="0%"
              right="0%"
              h="150px"
              w="100%"
              px={6}>
              <AbsoluteCenter w="85%">
                <Box py={1.5} px={0.5} background="#FCFF77" borderRadius="7px">
                  <HStack justifyContent="center">
                    <Icon
                      iconName="fi-rr-exclamation"
                      style={{fontSize: '14px', height: '14px', width: '14px'}}
                    />
                    <TextG10
                      fontSize={ticketTypeId == TICKET_TYPES.WILL_CALL ? '10px' : '8px'}
                      fontWeight="700"
                      color={theme.colors.darkGrey}>
                      {ticketTypeId == TICKET_TYPES.WILL_CALL
                        ? `THIS IS NOT TICKET - TICKET AT WILL CALL`
                        : `THIS IS NOT A TICKET - CHECK YOUR EMAIL FOR TICKETS`}
                    </TextG10>
                  </HStack>
                </Box>
              </AbsoluteCenter>
            </Box>
          )}
        </Box>
        <TextA18 fontWeight="900" textAlign="center">
          {ticket.name}
        </TextA18>
        {event ? (
          <Flex gap="4px" justify="center" align="center">
            <TextG10 whiteSpace="nowrap" fontWeight="700">Event:</TextG10>
            <TextG10 color={theme.colors.blue} onClick={() => navigate(`/event/${event.id}`)}>
              {event.title}
            </TextG10>
          </Flex>
        ) : (
          <Box h="14px" />
        )}
        {dashedLine}
        {ticket.willcall_name ? (
          <Flex justifyContent="space-between" mt="6px">
            <TextG12 fontWeight="500">Attendee Name:</TextG12>
            <TextG12>{ticket.willcall_name}</TextG12>
          </Flex>
        ) : (
          <Flex justifyContent="space-between" mt="6px">
            <TextG12 fontWeight="500">Attendee Name:</TextG12>
            <TextG12>{`${user?.name} ${user?.surname}`}</TextG12>
          </Flex>
        )}
        <Flex justifyContent="space-between" mt="6px">
          <TextG12 fontWeight="500">Ticket Count:</TextG12>
          <TextG12>{`${ticketNum}/${totalTickets}`}</TextG12>
        </Flex>
        <Flex justifyContent="space-between" mt="6px">
          <TextG12 fontWeight="500">Entry:</TextG12>
          <TextG12>1 Person</TextG12>
        </Flex>
        <Flex justifyContent="space-between" my="6px">
          <TextG12 fontWeight="500">Date:</TextG12>
          <TextG12>
            {getTicketDate(ticket.event.start_date_time, ticket.event.end_date_time)}
          </TextG12>
        </Flex>
        {dashedLine}
        <TextG10
          mb={2}
          fontWeight="500"
          textAlign="center">{`Ticket ID: ${ticket.uuid.toUpperCase()}`}</TextG10>
      </Flex>
    </Box>
  );
};

export default TicketDetails;
