import {Flex} from '@chakra-ui/react';
import React from 'react';
import {BlueButton} from '../button';
import Modal from '../Modals/Modal';
import {TextG12, TextG18} from '../typography';

const SignupRequiredModal = ({isOpen, setIsOpen, onButtonClicked}) => {
  return (
    <Modal
      closeButton
      modalPadding="10px 30px"
      isCentered
      header={
        <TextG18 fontSize="24px" mt="30px" textAlign="center" fontWeight="700">
          Sign Up Required
        </TextG18>
      }
      show={isOpen}
      parentCallback={() => setIsOpen(false)}
      children={
        <>
          <TextG12>
            In order to do this you must first join Walkabout! Click below to get started now
          </TextG12>
          <Flex justifyContent="center" pt={3} pb={3} gap="10px">
            <BlueButton
              onClick={() => {
                onButtonClicked();
              }}>
              Join Walkabout Now
            </BlueButton>
          </Flex>
        </>
      }
    />
  );
};

export default SignupRequiredModal;
