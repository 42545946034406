import React, {useEffect} from 'react';
import {
  Stack,
  Flex,
  useTheme,
  Box,
  Spacer,
  WrapItem,
  Center,
  Wrap,
  WithCSSVar,
} from '@chakra-ui/react';
import {TextG10, TextG12, TextG14} from '../../components/typography';
import dayjs from 'dayjs';
import Icon from '../../components/Icon';
import useBookmark from '../../hooks/useBookmark';
import ArticleSocialIcon from '../../components/Articles/ArticleSocialIcon';
import {ArticleType, Post} from '../../types/acrticle';
import TopicsOfInterest from '../TopicsOfInterest';
import {useSearchParams} from 'react-router-dom';

const Content = ({post}: {post: Post}) => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const {isBookmarked, handleOnBookmarkClick} = useBookmark(post, 'post');

  useEffect(() => {
    if (!!searchParams.get('bookmark') && post && !isBookmarked) {
      handleOnBookmarkClick(null);
    }
  }, [searchParams, post]);

  const iconStyle: React.CSSProperties = React.useMemo(
    () => ({
      color: theme.colors.lightBlue,
      fontSize: '1.5rem',
    }),
    [theme]
  );

  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: function (number, withoutSuffix) {
        return withoutSuffix ? 'now' : 'a few seconds';
      },
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: '1mth',
      MM: '%dmth',
      y: '1y',
      yy: '%dy',
    },
  });

  return (
    <>
      <Stack gap=".25rem" textAlign="start" p="1rem">
        <Wrap pb={2} spacing={3}>
          {post?.social_account && (
            <>
              <WrapItem>
                <ArticleSocialIcon social={post?.social_account?.social_network.name} />
              </WrapItem>
              <WrapItem alignItems={'center'}>
                <TextG12 fontWeight={500} color={theme.colors.grey}>
                  <Box as={'span'}>
                    {post?.social_account?.username && `@${post.social_account.username}`}
                  </Box>
                </TextG12>
              </WrapItem>
            </>
          )}
          <WrapItem alignItems={'center'}>
            <Center>
              <TextG10 color={theme.colors.grey}>
                {dayjs.utc(post.released_at).fromNow(true)}
              </TextG10>
            </Center>
          </WrapItem>
          <Spacer />
          <WrapItem alignItems={'center'}>
            <Flex minW="60px" direction="column" align="center" onClick={handleOnBookmarkClick}>
              {isBookmarked ? (
                <Icon iconName="fi-sr-bookmark" style={iconStyle} />
              ) : (
                <Icon iconName="fi-rr-bookmark" style={iconStyle} />
              )}
            </Flex>
          </WrapItem>
        </Wrap>

        {post.title && (
          <Box
            fontWeight="700"
            fontSize="14px"
            lineHeight="20px"
            fontFamily="Gordita"
            dangerouslySetInnerHTML={{__html: post.title}}
          />
        )}

        <Box
          // className="inner-content"
          // fontSize="14px"
          // lineHeight="20px"
          // fontFamily="Gordita"
          dangerouslySetInnerHTML={{__html: post.description}}
        />
      </Stack>
      <Stack gap=".25rem" textAlign="start" px="1rem">
        <Flex flexWrap="wrap" gap="10px">
          {post?.topics_of_interest && post?.topics_of_interest.length ? (
            <TopicsOfInterest topicsOfInterest={post?.topics_of_interest} />
          ) : null}
          {/* {post.topics_of_interest.map((topic) => {
            return (
              <Box key={topic?.id}>
                <Flex
                  filter="drop-shadow(0px 2px 25px rgba(0, 0, 0, 0.15))"
                  border={`1px solid ${theme.colors.lighterGrey}`}
                  borderRadius="22.5px"
                  justifyContent="center"
                  alignItems="center"
                  p={2}
                  gap=".5rem"
                  h="100%">
                  <Icon iconName={topic?.icon} centered={true} />
                  <TextG12>{topic?.name}</TextG12>
                </Flex>
              </Box>
            );
          })} */}
        </Flex>
      </Stack>
    </>
  );
};

export default Content;
