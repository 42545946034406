import {
  Container,
  Flex,
  Center,
  Divider,
  useTheme,
  AbsoluteCenter,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  HStack,
} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {BlueButtonFixedWidth} from '../../components/button';
import Icon from '../../components/Icon';
import QueryInfiniteScroll from '../../components/QueryInfiniteScroll';
import RequiresEarlyAccess from '../../components/RequiresEarlyAccess';
import {H3, TextG14, TextG16, TextG18} from '../../components/typography';
import useAuth from '../../hooks/useAuth';
import {useEventRSVPs} from '../../hooks/useEvents';
import CommunityChatCard from './ChatCard/CommunityChatCard';
import EventChatCard from './ChatCard/EventChatCard';
import LifestyleChatCard from './ChatCard/LifestyleChatCard';

const CHATS_TABS = ['Active', 'Closed'];

const Chats = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const {user} = useAuth();
  const userCommunities = user?.communities;
  const userHasMembership = user?.hasActiveMembership;

  useEffect(() => {
    window.history.scrollRestoration = 'auto';
  }, []);

  return (
    <Container pb={10} h="auto">
      <Tabs
        outline={'none !important'}
        index={tabIndex}
        onChange={(index) => setTabIndex(index)}
        isFitted
        isLazy
        lazyBehavior="keepMounted">
        <Flex
          flexDir="column"
          w="100vw"
          ml={-4}
          justifyContent="center"
          background={theme.colors.lightGrey}>
          <H3 mt="25px" mb="10px" mx={4} lineHeight="24px" color={theme.colors.black}>
            Chats
          </H3>
          <TabList w="100vw" justifyContent="center">
            {CHATS_TABS.map((tab, index) => (
              <Tab
                _selected={{
                  color: theme.colors.blue,
                  borderBottomColor: theme.colors.blue,
                  borderBottomWidth: '3px',
                }}
                key={index}>
                <TextG14
                  fontWeight={tabIndex == index ? 700 : 500}
                  color={tabIndex == index ? theme.colors.blue : theme.colors.grey}>
                  {tab}
                </TextG14>
              </Tab>
            ))}
          </TabList>
        </Flex>

        <TabPanels zIndex="1000" background="white" overflow="hidden !important">
          <TabPanel p={0}>
            <Flex flexDir="column">
              <TextG14 fontWeight="700" pt={4} pb={2}>
                Group Chat
              </TextG14>
              <HStack mb={2} gap={6} flexWrap="wrap" justifyContent="center">
                <LifestyleChatCard />;
              </HStack>
              <Divider />
            </Flex>
            {userCommunities && userCommunities.length > 0 && (
              <Flex flexDir="column">
                <TextG14 fontWeight="700" pt={4} pb={2}>
                  Community Chat
                </TextG14>
                <HStack mb={2} gap={6} flexWrap="wrap" justifyContent="center">
                  {userCommunities.map((community) => {
                    return <CommunityChatCard key={community.id} community={community} />;
                  })}
                </HStack>
                <Divider />
              </Flex>
            )}
            <Flex flexDir="column">
              <TextG16 fontWeight="700" pt={4} pb={2}>
                Event Chat
              </TextG16>
              <QueryInfiniteScroll
                gridGap="0"
                showItems={(event) => <EventChatCard event={event} />}
                hook={useEventRSVPs}
                endpointParams={{}}
                noItems={
                  <Center w="90%" textAlign="center" color={theme.colors.grey}>
                    <Flex flexDir="column" gap={8}>
                      <Icon
                        iconName="fi-rr-comment"
                        style={{fontSize: '48px', color: theme.colors.lightGrey}}
                      />
                      <TextG18 fontWeight="700" fontSize="24px" color="black">
                        No Active Event Chats
                      </TextG18>
                      <TextG14 fontWeight="500" px={12}>
                        Here’s where you’ll find all the chats you’re involved in.
                        <br />
                        <br />
                        You can enter a chat when you RSVP for an event, and message with others who
                        are interested and going.
                        <br />
                        <br />
                        More ways to chat are coming soon!
                      </TextG14>
                      <BlueButtonFixedWidth
                        onClick={() => navigate('/search/event')}
                        mx="auto"
                        mt={4}>
                        See Upcoming Events
                      </BlueButtonFixedWidth>
                    </Flex>
                  </Center>
                }
              />
            </Flex>
          </TabPanel>
          <TabPanel p={0}>
            <QueryInfiniteScroll
              gridGap="0"
              showItems={(event) => <EventChatCard event={event} closed />}
              hook={useEventRSVPs}
              endpointParams={{closed: 'true'}}
              noItems={
                <AbsoluteCenter w="90%" textAlign="center" color={theme.colors.grey}>
                  <Flex flexDir="column" gap={8}>
                    <Icon
                      iconName="fi-rr-comment"
                      style={{fontSize: '48px', color: theme.colors.lightGrey}}
                    />
                    <TextG18 fontWeight="700" fontSize="24px" color="black">
                      No Closed Chats
                    </TextG18>
                    <TextG14 fontWeight="500" px={12}>
                      Here’s where you’ll find all the chats you were involved in.
                      <br />
                      <br />
                      Chats will close three hours after event ends and will appear here in the
                      Closed chats tab.
                    </TextG14>
                  </Flex>
                </AbsoluteCenter>
              }
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
};

export default Chats;
