import {useEffect, useState} from 'react';
import {useQuery, useInfiniteQuery} from '@tanstack/react-query';
import {fetchCheckInHistory, fetchPointsHistory, fetchRedemptionHistory} from '../endpoints/api';
import {union, orderBy, uniqBy} from 'lodash';
import useAuth from '../hooks/useAuth';

export const useGetCheckInHistory = (page) => {
  return useQuery(['check-in-history', page], () =>
    fetchCheckInHistory({page: page, cancelReq: null, ...{perPage: 4}})
  );
};

export const useGetRedemptionHistory = (page) => {
  return useQuery(['redemption-history', page], () =>
    fetchRedemptionHistory({page: page, cancelReq: null, ...{perPage: 4}})
  );
};

export const useInfiniteRedemptionHistory = () => {
  return useInfiniteQuery(
    ['infinite-redemption-history'],
    ({pageParam = 1}) => fetchRedemptionHistory({page: pageParam, cancelReq: null, per_page: 10}),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.current_page !== lastPage.data.last_page) {
          return lastPage.data.current_page + 1;
        }
      },
    }
  );
};

export const useGetPointHistory = (page) => {
  return useQuery(['point-history', page], () => fetchPointsHistory({page: page, perPage: 4}));
};

export const usePointHistory = () => {
  const [totalHistory, setTotalHistory] = useState<Array<any>>([]);
  const [pointHistory, setPointHistory] = useState<Array<any>>([]);
  const [redemptionHistory, setRedemptionHistory] = useState<Array<any>>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const getPointHistory = useGetPointHistory(page);
  const getRedemptionHistory = useGetRedemptionHistory(page);
  const {reFetchUserData} = useAuth();

  const isLoading = getPointHistory.isLoading || getRedemptionHistory.isLoading;

  useEffect(() => {
    if (getPointHistory.data?.data.data) {
      setPointHistory((prevHistory) =>
        uniqBy(
          orderBy(union(prevHistory, getPointHistory.data?.data.data), 'created_at', 'desc'),
          (e) => e.id
        )
      );
    }
  }, [getPointHistory.data?.data.data]);

  useEffect(() => {
    if (getRedemptionHistory.data?.data?.data) {
      setRedemptionHistory((prevHistory) =>
        uniqBy(
          orderBy(
            union(prevHistory, getRedemptionHistory.data?.data.data),
            'created_at',
            'desc'
          ),
          (e) => e.id
        )
      );
    }
  }, [getRedemptionHistory.data?.data?.data.length]);

  useEffect(() => {
    setTotalHistory(orderBy(union(pointHistory, redemptionHistory), 'created_at', 'desc'));
    setHasMore(
      getRedemptionHistory.data?.data.last_page >
        getRedemptionHistory.data?.data.current_page ||
        getPointHistory.data?.data.last_page > getPointHistory.data?.data.current_page
    );
    reFetchUserData();
  }, [pointHistory, redemptionHistory]);

  return {setPage, isLoading, hasMore, totalHistory};
};
