import {useQueryClient, useMutation, useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {
  fetchEvent,
  updateEventAttendance,
  fetchEventRSVPStats,
  updateEventNotificationPreference,
  fetchPublicEvent,
} from '../endpoints/api';
import {useDispatch} from 'react-redux';
import {OPTIONS} from '../constants/eventOptions';
import {RSVPModalPopupActions} from '../state/RSVPModalPopup/RSVPModalPopupSlice';
import useAuth from './useAuth';
import {useAddBookmark} from './useBookmark';
import {EventNotificationsModalActions} from '../state/EventNotificationsModal/EventNotificationsModalSlice';

export enum EventRSVPStatus {
  NO_STATUS = 0,
  INTERESTED = 1,
  GOING = 2,
  NOT_GOING = 3,
  NOT_INTERESTED = 4,
}

export function useFetchEvent(eventId: number | undefined, enabled = true) {
  return useQuery(['event', eventId], () => fetchEvent({id: Number(eventId)}), {
    enabled: enabled && !!eventId && !isNaN(eventId) && typeof eventId === 'number',
  });
}

export function useFetchPublicEvent(eventId: number | undefined) {
  return useQuery(['public_event', eventId], () => fetchPublicEvent({id: Number(eventId)}), {
    enabled: !!eventId && typeof eventId === 'number',
  });
}

export function useSetAttendance() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const {user} = useAuth();
  const {mutateAsync} = useAddBookmark();

  return useMutation(
    ({
      eventId,
      status,
      avoidDispatchRSVPModalPopupActions = true,
    }: {
      eventId: number;
      prevStatus: number;
      status: number;
      avoidDispatchRSVPModalPopupActions?: boolean;
    }) => updateEventAttendance(eventId, status),
    {
      // This will at least optimitisticly update on the event page,
      // but the event cards on feeds would still need to wait for onSettled to be udpated
      onMutate: async (newStatus) => {
        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({queryKey: ['event', newStatus.eventId]});
        await queryClient.cancelQueries({queryKey: ['meetup', newStatus.eventId]});
        // Snapshot the previous value
        const previousEventStatus: AxiosResponse | null | undefined = queryClient.getQueryData([
          'event',
          newStatus.eventId,
        ]);

        const previousMeetupStatus: AxiosResponse | null | undefined = queryClient.getQueryData([
          'meetup',
          newStatus.eventId,
        ]);

        if (previousEventStatus) {
          // Optimistically update to the new value
          queryClient.setQueryData(['event', newStatus.eventId], {
            ...previousEventStatus,
            data: {
              ...previousEventStatus.data,
              rsvp_status: {
                status: EventRSVPStatus[newStatus.status],
                status_value: newStatus.status,
              },
            },
          });
        }

        if (previousMeetupStatus) {
          queryClient.setQueryData(['meetup', newStatus.eventId], {
            ...previousEventStatus,
            data: {
              ...previousMeetupStatus.data,
              rsvp_status: {
                status: EventRSVPStatus[newStatus.status],
                status_value: newStatus.status,
              },
            },
          });
        }

        // Return a context with the previous and new status
        return {previousStatus: previousEventStatus, newStatus};
      },
      onSettled(
        response,
        error,
        {eventId, prevStatus, status, avoidDispatchRSVPModalPopupActions},
        context
      ) {
        const shouldShowRSVPModal = (storageKey) => {
          const doNotShowAgainRSVPModalObj = JSON.parse(localStorage.getItem(storageKey) || '[]');
          const index = doNotShowAgainRSVPModalObj.findIndex((obj) => obj.statusId === status);
          if (index !== -1 && doNotShowAgainRSVPModalObj[index].count >= 3) {
            avoidDispatchRSVPModalPopupActions = false;
          }
        };
        shouldShowRSVPModal('do-not-show-again-rsvp-modal');

        if (status === EventRSVPStatus.GOING || status === EventRSVPStatus.INTERESTED)
          mutateAsync({articleId: eventId, type: 'event'});

        if (
          prevStatus !== status &&
          avoidDispatchRSVPModalPopupActions &&
          (((prevStatus === 0 || prevStatus === EventRSVPStatus.NOT_GOING) &&
            (status === EventRSVPStatus.INTERESTED || status === EventRSVPStatus.GOING)) ||
            status === EventRSVPStatus.NOT_GOING ||
            (prevStatus !== 0 && status === EventRSVPStatus.NOT_INTERESTED)) //CT-2507 Do not show “I’m not interested” popup if the user is selecting this status from default
        ) {
          dispatch(
            RSVPModalPopupActions.pushRSVPStatus({
              eventId,
              prevStatus,
              status,
              ...OPTIONS[status].popup,
            })
          );
        } else {
          const showEventModal = !!!(user?.emailEnabled && user.pushEnabled && user.smsEnabled);
          if (showEventModal)
            dispatch(EventNotificationsModalActions.pushEventNotifications({eventId}));
        }

        if (
          (prevStatus === EventRSVPStatus.NOT_INTERESTED &&
            (status === EventRSVPStatus.GOING || status === EventRSVPStatus.INTERESTED)) ||
          (prevStatus === EventRSVPStatus.INTERESTED && status === EventRSVPStatus.GOING) ||
          (prevStatus === EventRSVPStatus.GOING && status === EventRSVPStatus.INTERESTED)
        ) {
          OPTIONS[status].message(
            'Thanks! Your RSVP is updated, and the event is added to your Bookmarks.'
          );
        }

        queryClient
          .getQueryCache()
          .getAll()
          .filter(
            (query) =>
              (query.queryKey.includes('offers') &&
                (query.queryKey as any)[1].articleType === 'eventId' &&
                (query.queryKey as any)[1].articleId === `${eventId}`) ||
              query.queryKey.includes('infinite-global-search')
          )
          .forEach((query) => {
            return queryClient.invalidateQueries(query.queryKey);
          });
        queryClient.invalidateQueries([
          'infinite-offers',
          {articleType: 'eventId', articleId: eventId},
          'events',
          'event',
          eventId,
        ]);
        queryClient.invalidateQueries(['events']);
        queryClient.invalidateQueries(['event', eventId]);
        queryClient.invalidateQueries(['infinite-events']);
        queryClient.invalidateQueries(['RSVPStats', eventId]);
        queryClient.invalidateQueries(['current-home-context-content']);
        queryClient.invalidateQueries(['infinite-bookmarks']);
        queryClient.invalidateQueries(['place']);
        queryClient.invalidateQueries(['infinite-events-rsvps']);
        queryClient.invalidateQueries(['infinite_meetups']);
        queryClient.invalidateQueries(['infinite-global-search']);
      },
      // onSuccess: (response, {eventId, status}) => {
      //   queryClient
      //     .getQueryCache()
      //     .getAll()
      //     .filter(
      //       (query) =>
      //         (query.queryKey.includes('offers') &&
      //           (query.queryKey as any)[1].articleType === 'eventId' &&
      //           (query.queryKey as any)[1].articleId === `${eventId}`) ||
      //         query.queryKey.includes('infinite-global-search')
      //     )
      //     .forEach((query) => {
      //       return queryClient.invalidateQueries(query.queryKey);
      //     });
      //   queryClient.invalidateQueries([
      //     'infinite-offers',
      //     {articleType: 'eventId', articleId: eventId},
      //     'events',
      //     'event',
      //     eventId,
      //   ]);
      //   queryClient.invalidateQueries(['events']);
      //   queryClient.invalidateQueries(['event', eventId]);
      //   queryClient.invalidateQueries(['infinite-events']);
      //   queryClient.invalidateQueries(['RSVPStats', eventId]);
      //   queryClient.invalidateQueries(['current-home-context-content']);
      //   queryClient.invalidateQueries(['infinite-bookmarks']);
      //   queryClient.invalidateQueries(['place']);
      // },
    }
  );
}

export function useFetchEventRSVPStats(eventId: number, enabled?: boolean) {
  return useQuery(['RSVPStats', eventId], () => fetchEventRSVPStats(eventId), {enabled: enabled});
}

export function useUpdateNotificationPreference() {
  return useMutation(
    ({eventId, receiveNotifications}: {eventId: number; receiveNotifications: boolean}) =>
      updateEventNotificationPreference(eventId, receiveNotifications)
  );
}
