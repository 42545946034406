import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import ChatComment, {ChatMedia} from '../ChatComment';
import {AbsoluteCenter, Center, Flex, Spinner, useTheme} from '@chakra-ui/react';
import {TextG10, TextG14} from '../../typography';
import ChatHeader from '../ChatHeader/CommunityChatHeader';
import Icon from '../../Icon';
import useAuth from '../../../hooks/useAuth';
import {useFetchChatUsers} from '../../../hooks/useEvents';
import {
  useCreateCommunityComment,
  useUpdateCommunityComment,
  useDeleteCommunityComment,
  useReportCommunityComment,
  useHelpfulCommunityComment,
  useFetchCommunity,
  useFetchCommunityComment,
  useAddCommentImage,
  useDeleteCommentImage,
} from '../../../hooks/useCommunityComments';
import {getImage} from '../../../pages/Profile/Edit/CropImage';

const CommentThread = () => {
  const {user} = useAuth();
  const [isErrored, setIsErrored] = useState(false);
  const {communityId, commentId} = useParams();
  const fetchComment = useFetchCommunityComment({
    communityId: Number(communityId),
    commentId,
    enabled: !isErrored,
  });
  const fetchCommunity = useFetchCommunity(Number(communityId));
  const community = fetchCommunity.data?.data;
  const comment = fetchComment.data?.data;
  const navigate = useNavigate();
  const theme = useTheme();
  const [headerHeight, setHeaderHeight] = useState(0);

  const addCommunityComment = useCreateCommunityComment();
  const updateCommunityComment = useUpdateCommunityComment();
  const deleteCommunityComment = useDeleteCommunityComment();
  const reportCommunityComment = useReportCommunityComment();
  const helpfulCommunityComment = useHelpfulCommunityComment();
  const addCommentImage = useAddCommentImage();
  const deleteCommentImage = useDeleteCommentImage();

  const addComment = async (content: string, parent_id?: string, imageURLs?: Array<any>) => {
    if (content.length > 0) {
      await addCommunityComment.mutateAsync({
        communityId: community.id,
        content,
        parent_id,
        user,
      });

      if (imageURLs && imageURLs.length > 0 && comment) {
        imageURLs.map(async (media) => {
          const croppedImage = await getImage(media, null);

          if (communityId && croppedImage) {
            await addCommentImage.mutateAsync({
              formData: croppedImage,
              target_id: communityId,
              comment_id: comment.data.id,
            });
          }
        });
      }
    }
  };

  const updateComment = async (
    commentId: string,
    content: string,
    parent_id?: string,
    imageURLs?: Array<any>,
    mediaToDelete?: ChatMedia[]
  ) => {
    if (content.length > 0) {
      await updateCommunityComment.mutateAsync({
        communityId: community.id,
        commentId,
        content,
      });

      if (imageURLs && imageURLs.length > 0) {
        imageURLs.map(async (media) => {
          const croppedImage = await getImage(media, null);
          if (communityId && croppedImage && commentId) {
            await addCommentImage.mutateAsync({
              formData: croppedImage,
              target_id: communityId,
              comment_id: commentId,
            });
          }
        });
      }

      if (mediaToDelete && mediaToDelete.length > 0) {
        mediaToDelete.map(async (media) => {
          if (media.id) {
            await deleteCommentImage.mutateAsync({mediaId: media.id});
          }
        });
      }
    }
  };

  const deleteComment = async (commentId: string) => {
    await deleteCommunityComment.mutateAsync({
      communityId: community.id,
      commentId,
    });
  };

  const reportComment = async (commentId: string) => {
    await reportCommunityComment.mutateAsync({
      communityId: community.id,
      commentId,
    });
  };

  const helpfulComment = async (commentId: string) => {
    await helpfulCommunityComment.mutateAsync({
      communityId: community.id,
      commentId,
    });
  };
  useEffect(() => {
    if (fetchComment.isError) setIsErrored(true);
  }, [fetchComment]);

  return (
    <Flex flexDir="column" pt={`${headerHeight}px`} px={4} pb="77px">
      <ChatHeader
        addComment={addComment}
        updateComment={updateComment}
        deleteComment={deleteComment}
        reportComment={reportComment}
        helpfulComment={helpfulComment}
        community={community}
        setHeaderHeight={setHeaderHeight}
        showTabs={false}
      />
      {fetchComment.isLoading || fetchCommunity.isLoading ? (
        <Center h={`calc(100vh - ${headerHeight}px)`}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor={theme.colors.lightGrey}
            color={theme.colors.lightBlue}
            size="lg"
          />
        </Center>
      ) : !comment || isErrored ? (
        <AbsoluteCenter
          w="95%"
          textAlign="center"
          color={theme.colors.grey}
          pt={`${headerHeight / 2}px`}>
          <Flex flexDir="column" gap={8}>
            <Icon iconName="fi-rr-comment" style={{fontSize: '48px'}} />
            <TextG14 fontWeight="500">Sorry, this Comment is no longer available.</TextG14>
          </Flex>
        </AbsoluteCenter>
      ) : (
        <>
          <Flex w="100%" justify="space-between" mt={2} color={theme.colors.blue}>
            {comment.parent_id && (
              <TextG10
                onClick={() => navigate(`/communities/${communityId}/chat/${comment.parent_id}`)}>
                See parent thread
              </TextG10>
            )}
            <TextG10 onClick={() => navigate(`/communities/${communityId}/chat`)}>
              See full discussion
            </TextG10>
          </Flex>
          <ChatComment
            fetchUsersHook={useFetchChatUsers}
            addComment={addComment}
            updateComment={updateComment}
            deleteComment={deleteComment}
            reportComment={reportComment}
            helpfulComment={helpfulComment}
            isReply={comment.parent_id}
            comment={comment}
            articleId={community.id}
            name={community.name}
            isRSVPd={true}
            repliesShowingDefault
          />
        </>
      )}
    </Flex>
  );
};

export default CommentThread;
