import {fetchUserDiscounts} from '../endpoints/api';
import {useInfiniteQuery, useQuery} from '@tanstack/react-query';

export function useFetchUserDiscounts() {
  return useQuery(['user-discounts'], () => fetchUserDiscounts());
}

export const useInfiniteUserDiscounts = () => {
  return useInfiniteQuery(['infinite-user-discounts'], ({pageParam = 1}) => fetchUserDiscounts(), {
    getNextPageParam: (lastPage) => {
      if (lastPage.data.current_page !== lastPage.data.last_page) {
        return lastPage.data.current_page + 1;
      }
    },
  });
};
