import {Container} from '@chakra-ui/react';
import React from 'react';
import OfferCard from '../../components/Articles/Offers/OfferCard';
import DiscountCard from '../../components/DiscountCard';
import QueryInfiniteScroll from '../../components/QueryInfiniteScroll';
import {useInfiniteUserDiscounts} from '../../hooks/useUserDiscounts';
import {OfferType} from '../../types/acrticle';

const MyDiscounts = () => {
  return (
    <Container pt={6} px={'0 !important'} pb={'initial'} h="auto">
      <QueryInfiniteScroll
        showItems={(discount) =>
          discount.offer_type == OfferType.DISCOUNT ? (
            <DiscountCard discount={discount} />
          ) : (
            <OfferCard offer={discount} />
          )
        }
        hook={useInfiniteUserDiscounts}
        endpointParams={{}}
      />
    </Container>
  );
};

export default MyDiscounts;
