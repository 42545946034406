import {useInfiniteQuery, useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {tail} from 'lodash';
import {ToastMessage} from '../components/toast';
import {
  addChatMedia,
  createEventComment,
  deleteChatMedia,
  deleteEventComment,
  fetchChatMedia,
  fetchEventComment,
  fetchEventComments,
  fetchFeaturedEventComments,
  fetchReplyMentionComments,
  helpfulComment,
  reportComment,
  updateEventComment,
} from '../endpoints/api';
import {User} from './useAuth';
import {EventRSVPStatus} from './useEvent';

const INTERVAL_MS = 5000; // 5 seconds

interface FetchCommentOptions {
  eventId: number;
  page?: string;
}

export function useFetchEventComments({eventId, page = '1'}: FetchCommentOptions) {
  return useQuery(['event_comments', eventId], () => fetchEventComments({eventId, page}), {
    enabled: typeof eventId === 'number',
    // Refetch the data every INTERVAL_MS
    refetchInterval: INTERVAL_MS,
  });
}

export function useFetchFeaturedEventComments({eventId, page = '1'}: FetchCommentOptions) {
  return useQuery(
    ['featured_event_comments', eventId],
    () => fetchFeaturedEventComments({eventId, page}),
    {
      enabled: typeof eventId === 'number',
      // Refetch the data every INTERVAL_MS
      refetchInterval: INTERVAL_MS,
    }
  );
}

export const useInfiniteFeaturedEventComments = (eventId: number, enabled: boolean = true) => {
  return useInfiniteQuery(
    ['infinite_featured_event_comments', eventId],
    ({pageParam = 1}) => fetchFeaturedEventComments({eventId, page: pageParam}),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.current_page !== lastPage.data.last_page) {
          return lastPage.data.current_page + 1;
        }
      },
      refetchInterval: INTERVAL_MS,
      enabled: enabled && typeof eventId === 'number',
    }
  );
};

export function useFetchRepliesAndMentions(eventId: number, enabled: boolean = true) {
  return useQuery(['event_comments', eventId], () => fetchReplyMentionComments({eventId}), {
    enabled: enabled && typeof eventId === 'number',
    // Refetch the data every INTERVAL_MS
    refetchInterval: INTERVAL_MS,
  });
}

export function useFetchEventComment({eventId, commentId, enabled}) {
  return useQuery(
    ['event_comment', eventId, commentId],
    () => fetchEventComment({eventId, commentId}),
    {
      enabled: enabled && typeof eventId === 'number',
      // Refetch the data every INTERVAL_MS
      refetchInterval: INTERVAL_MS,
      retry: 1,
    }
  );
}

export const useInfiniteEventComments = (eventId: number, enabled: boolean = true) => {
  return useInfiniteQuery(
    ['infinite_event_comments', eventId],
    ({pageParam = 1}) => fetchEventComments({eventId, page: pageParam}),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.current_page !== lastPage.data.last_page) {
          return lastPage.data.current_page + 1;
        }
      },
      refetchInterval: INTERVAL_MS,
      enabled: enabled && typeof eventId === 'number',
    }
  );
};

export const useCreateEventComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      eventId,
      content,
      parent_id,
      is_featured,
      user,
      rsvp_status,
    }: {
      eventId: number;
      content: string;
      parent_id?: string;
      is_featured?: boolean;
      user?: User;
      rsvp_status?: EventRSVPStatus;
    }) => createEventComment({eventId, content, parent_id, is_featured}),
    {
      onMutate: async (newComment) => {
        if (newComment.parent_id) return;
        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({
          queryKey: ['infinite_event_comments', newComment.eventId],
        });

        // Snapshot the previous value
        const previousComments: any = queryClient.getQueryData([
          'infinite_event_comments',
          newComment.eventId,
        ]);
        if (previousComments && previousComments.pages[0]) {
          const newData = {
            ...previousComments,
            pages: [
              {
                ...previousComments.pages[0],
                data: {
                  ...previousComments.pages[0].data,
                  data: [{id: '1', ...newComment}, ...previousComments.pages[0].data.data],
                },
              },
              ...tail(previousComments.pages),
            ],
          };

          // Optimistically update to the new value
          queryClient.setQueryData(['infinite_event_comments', newComment.eventId], newData);
        }

        // Return a context with the previous and new comment
        return {previousComments, newComment};
      },
      onSettled: (newComment, error, variables, context) => {
        if (error && error instanceof AxiosError) {
          ToastMessage({
            status: 'error',
            text: error.message,
          });
        }
        queryClient.invalidateQueries(['infinite_event_comments', variables.eventId]);
        queryClient.invalidateQueries(['event_comments', variables.eventId]);
      },
    }
  );
};

export const useUpdateEventComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({eventId, commentId, content}: {eventId: number; commentId: string; content: string}) =>
      updateEventComment({eventId, commentId, content}),
    {
      onSettled: (newComment, error, variables, context) => {
        if (error && error instanceof AxiosError) {
          ToastMessage({
            status: 'error',
            text: error.message,
          });
        }
        queryClient.invalidateQueries(['infinite_event_comments', variables.eventId]);
        queryClient.invalidateQueries(['event_comments', variables.eventId]);
      },
    }
  );
};

export const useDeleteEventComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({eventId, commentId}: {eventId: number; commentId: string}) =>
      deleteEventComment({eventId, commentId}),
    {
      onSettled: (newComment, error, variables, context) => {
        if (error && error instanceof AxiosError) {
          ToastMessage({
            status: 'error',
            text: error.message,
          });
        }
        queryClient.invalidateQueries(['infinite_event_comments', variables.eventId]);
        queryClient.invalidateQueries(['event_comments', variables.eventId]);
      },
    }
  );
};

export const useReportComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({eventId, commentId}: {eventId: number; commentId: string}) =>
      reportComment({eventId, commentId}),
    {
      onSettled: (newComment, error, variables, context) => {
        queryClient.invalidateQueries(['infinite_event_comments', variables.eventId]);
        queryClient.invalidateQueries(['event_comments', variables.eventId]);
      },
    }
  );
};

export const useHelpfulComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({eventId, commentId}: {eventId: number; commentId: string}) =>
      helpfulComment({eventId, commentId}),
    {
      onSettled: (newComment, error, variables, context) => {
        queryClient.invalidateQueries(['infinite_event_comments', variables.eventId]);
        queryClient.invalidateQueries(['event_comments', variables.eventId]);
      },
    }
  );
};

export const useAddCommentImage = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      formData,
      target_id,
      comment_id,
    }: {
      formData: FormData;
      target_id: string;
      comment_id: string;
    }) =>
      addChatMedia({
        formData,
        target_type: 'event',
        target_id,
        comment_type: 'event_comment',
        comment_id,
      }),
    {
      onSettled: (newComment, error, variables, context) => {
        if (error && error instanceof AxiosError) {
          ToastMessage({
            status: 'error',
            text: error.message,
          });
        }
        queryClient.invalidateQueries(['infinite_event_comments', variables.target_id]);
        queryClient.invalidateQueries(['event_comments', variables.target_id]);
      },
    }
  );
};

export const useDeleteCommentImage = () => {
  const queryClient = useQueryClient();
  return useMutation(({mediaId}: {mediaId: string}) => deleteChatMedia({mediaId}), {
    onSettled: (newComment, error, variables, context) => {
      if (error && error instanceof AxiosError) {
        ToastMessage({
          status: 'error',
          text: error.message,
        });
      }
      queryClient.invalidateQueries(['infinite_event_comments']);
      queryClient.invalidateQueries(['event_comments']);
    },
  });
};

export const useFetchCommentMedia = ({mediaId}) => {
  return useQuery(['event_comment_media', mediaId], () => fetchChatMedia({mediaId}), {
    enabled: typeof mediaId === 'number',
  });
};
