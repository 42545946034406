import {Container, Flex, VStack} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import EventCard from '../../components/Articles/EventCard';
import {useFetchPublicEvents} from '../../hooks/useEvents';

const PublicEvents = () => {
  const {data} = useFetchPublicEvents();
  const events = data?.data?.data;
  const navigate = useNavigate();

  if (events == null) return null;

  return (
    <Container h="auto" pb={4}>
      <Flex flexDir="column" gap="15px">
        {events.map((event) => (
          <EventCard
            key={event.id}
            event={event}
            onClick={() => {
              navigate(`/event/${event.id}/preview`);
            }}
            preview={true}
          />
        ))}
      </Flex>
    </Container>
  );
};

export default PublicEvents;
