import {Button, HStack, Input} from '@chakra-ui/react';
import React from 'react';
import {parseTicketPrice} from '..';
import {TextG10, TextG14, TextG12} from '../../../components/typography';
import {StyledCheckCircleFilled} from '../../../constants/eventOptions';
import {passIsSignature} from '../../../utils/membership';
import {numberToCurrency, renderPointRatio, renderPointsToDollars} from '../../../utils/price';
import a from 'indefinite';
import { BlueButton } from '../../../components/button';

export const getPaymentOptions = ({
  event,
  user,
  ticket,
  pass,
  theme,
  handlePassApplied,
  handlePassRemoved,
  handlePointsEnteredChanged,
  handlePointsApplied,
  handlePointsEdit,
  handleCardApplied,
  handleCardRemoved,
  numPointsEntered,
  numPointsApplied,
  cardPaymentAmount,
  passEnabled = true,
  pointsEnabled = true,
  eligibleUserPasses,
  purchasablePass,
  navigate,
  setShowUpgradeModal,
}) => {
  const eligiblePass = eligibleUserPasses[0];
  const passType = event?.pass_types?.length > 0 && event?.pass_types[0];
  const isSignature = passIsSignature(passType);
  const isEssential = user?.activeMembershipPlan?.name.includes('Essential');

  const array = [
    {
      id: 'card_payment',
      name: 'Credit/Debit Card',
      description: `We accept all major cards and apple pay.`,
      highlightElement: (
        <Button
          onClick={handleCardApplied}
          pos="relative"
          // bottom={2}
          right={-8}
          alignSelf="self-end"
          mt="5px !important"
          h="auto"
          w="55px"
          py="6px"
          px="18px"
          borderRadius="22px"
          background={theme.colors.blue}
          color="white">
          <TextG10>Add</TextG10>
        </Button>
      ),
      appliedElement: () => {
        return (
          <>
            <HStack>
              <StyledCheckCircleFilled w="16px" h="16px" fill="green" color="white" />
              <TextG14 color={theme.colors.darkGrey} fontWeight="700">
                Card Applied
              </TextG14>
            </HStack>
            <TextG12 color={theme.colors.red}>{`${numberToCurrency(
              cardPaymentAmount
            )} will be charged to your card in the next step`}</TextG12>
            <TextG10
              onClick={handleCardRemoved}
              pos="absolute"
              bottom={3}
              right={4}
              color={theme.colors.blue}
              fontWeight="700">
              Edit
            </TextG10>
          </>
        );
      },
    },
  ];

  const pointsElement = {
    id: 'walkabout_points',
    name: 'Walkabout Points',
    description: `Apply your points (${renderPointRatio(
      ticket ? ticket.points_value : pass.denomination
    )}), then pay the rest—easy as that!`,
    availability: `Points Available: ${user?.pointsTotal}`,
    highlightElement: (
      <HStack w="110%" justifyContent="space-between">
        <Input
          type="number"
          value={Number(numPointsEntered).toString()}
          onChange={(e) => handlePointsEnteredChanged(e)}
        />
        <Button
          onClick={handlePointsApplied}
          h="40px"
          w="80px"
          // h="auto"
          // w="60px"
          py="8px"
          px="16px"
          borderRadius="8px"
          background={theme.colors.blue}
          color="white">
          <TextG10>Apply</TextG10>
        </Button>
      </HStack>
    ),
    appliedElement: (
      <>
        <HStack>
          <StyledCheckCircleFilled w="16px" h="16px" fill="green" color="white" />
          <TextG14 color={theme.colors.darkGrey} fontWeight="700">
            Walkabout Points
          </TextG14>
        </HStack>
        <TextG12
          color={
            theme.colors.darkGrey
          }>{`Points Applied: ${numPointsApplied} (${renderPointsToDollars(
          numPointsApplied,
          ticket ? ticket.points_value : pass.denomination
        )})`}</TextG12>
        <TextG10
          onClick={handlePointsEdit}
          pos="absolute"
          bottom={3}
          right={4}
          color={theme.colors.blue}
          fontWeight="700">
          Edit
        </TextG10>
      </>
    ),
  };

  const passElement = eligiblePass
    ? {
        id: 'user_pass',
        name: `Use your ${eligiblePass.pass.title} Pass`,
        discountAmount: Number(eligiblePass.pass.denomination),
        description: `Use your ${
          eligiblePass.pass.title
        } Pass for a free ticket—up to ${numberToCurrency(eligiblePass.pass.denomination)}
          on eligible events.`,
        availabilityColor: eligibleUserPasses.length <= 0 ? 'red' : undefined,
        availability: `Passes Available: ${eligibleUserPasses.length}`,
        highlightElement: (passDiscountAmount, ticketPrice) => {
          const discAmount = Math.min(passDiscountAmount, parseTicketPrice(ticketPrice));
          return (
            <BlueButton
              onClick={() => handlePassApplied(discAmount)}
              pos="absolute"
              bottom={2}
              right={4}
              h="auto"
              w="90px"
              py="6px"
              px="18px"
              borderRadius="22px"
              background={theme.colors.blue}
              color="white">
              <TextG10>Apply pass</TextG10>
            </BlueButton>
          );
        },
        appliedElement: (passDiscountAmount, ticketPrice) => {
          const discAmount = Math.min(passDiscountAmount, parseTicketPrice(ticketPrice));
          return (
            <>
              <HStack>
                <StyledCheckCircleFilled w="16px" h="16px" fill="green" color="white" />
                <TextG14 color={theme.colors.darkGrey} fontWeight="700">
                  {`${eligiblePass.pass.title} Pass Applied`}
                </TextG14>
              </HStack>
              <TextG12 color={theme.colors.darkGrey}>{`-${numberToCurrency(
                discAmount
              )} has been successfully applied towards your balance`}</TextG12>
              <TextG10
                fontWeight="500"
                color={theme.colors.darkGrey}>{`amount applied: ${numberToCurrency(
                discAmount
              )}`}</TextG10>
              <TextG10
                onClick={handlePassRemoved}
                pos="absolute"
                bottom={3}
                right={4}
                color={theme.colors.blue}
                fontWeight="700">
                Remove
              </TextG10>
            </>
          );
        },
      }
    : isSignature && !user.hasActiveMembership
    ? {
        id: 'user_pass',
        name: <span>{`Use a Signature Pass`}</span>,
        description: (
          <span style={{marginBottom: '14px', display: 'inline-block'}}>
            Upgrade to Local Lifestyle+ and get one Signature Pass good for this type of event every
            month.
          </span>
        ),
        background: 'linear-gradient(173deg, #FEF4E6 -9.4%, #FFEDF6 95.35%);',
        highlightElement: (
          <Button
            onClick={() => setShowUpgradeModal(true)}
            pos="absolute"
            bottom={2}
            right={4}
            h="auto"
            w="90px"
            py="6px"
            px="18px"
            borderRadius="22px"
            background={theme.colors.blue}
            color="white">
            <TextG10>Upgrade Now!</TextG10>
          </Button>
        ),
      }
    : !eligiblePass && isSignature && isEssential
    ? {
        id: 'user_pass',
        name: <span>{`Use a Signature Pass`}</span>,
        description: (
          <span style={{marginBottom: '10px', display: 'inline-block'}}>
            Upgrade to Local Lifestyle+ and get one Signature Pass good for this type of event every
            month.
          </span>
        ),
        availabilityColor: 'red',
        availability: `Passes Available: 0`,
        background: 'linear-gradient(173deg, #FEF4E6 -9.4%, #FFEDF6 95.35%);',
        highlightElement: (
          // <Button
          //   onClick={() => navigate(`/memberships`)}
          //   pos="absolute"
          //   bottom={2}
          //   right={4}
          //   h="auto"
          //   w="90px"
          //   py="6px"
          //   px="18px"
          //   borderRadius="22px"
          //   background={theme.colors.blue}
          //   color="white">
          //   <TextG10>Upgrade Now!</TextG10>
          // </Button>
          <Button
            onClick={() => setShowUpgradeModal(true)}
            pos="absolute"
            bottom={2}
            right={4}
            h="auto"
            w="90px"
            py="6px"
            px="18px"
            borderRadius="22px"
            background={theme.colors.blue}
            color="white">
            <TextG10>Upgrade Now!</TextG10>
          </Button>
        ),
      }
    : !eligiblePass && !user.hasActiveMembership
    ? {
        id: 'user_pass',
        name: <span>{`Use ${a(passType.name)} Pass`}</span>,
        description: (
          <span style={{marginBottom: '10px', display: 'inline-block'}}>
            Premium members get experience passes every month to attend events like this!
          </span>
        ),
        background: 'linear-gradient(173deg, #FEF4E6 -9.4%, #FFEDF6 95.35%);',
        highlightElement: (
          <Button
            onClick={() => navigate(`/memberships`)}
            pos="absolute"
            bottom={2}
            right={4}
            h="auto"
            w="90px"
            py="6px"
            px="18px"
            borderRadius="22px"
            background={theme.colors.blue}
            color="white">
            <TextG10>Upgrade Now!</TextG10>
          </Button>
        ),
      }
    : // : !eligiblePass && purchasablePass
      // ? {
      //     id: 'user_pass',
      //     name: <span>{`Use ${a(purchasablePass.title)} Pass`}</span>,
      //     description: (
      //       <span style={{marginBottom: '10px', display: 'inline-block'}}>
      //         You don't have any of these passes right now! Buy some now!
      //       </span>
      //     ),
      //     availabilityColor: 'red',
      //     availability: `Passes Available: 0`,
      //     background: 'linear-gradient(173deg, #FEF4E6 -9.4%, #FFEDF6 95.35%);',
      //     highlightElement: (
      //       <Button
      //         onClick={() => navigate(`/pass/${purchasablePass?.id}/purchase`)}
      //         pos="absolute"
      //         bottom={2}
      //         right={4}
      //         h="auto"
      //         w="90px"
      //         py="6px"
      //         px="18px"
      //         borderRadius="22px"
      //         background={theme.colors.blue}
      //         color="white">
      //         <TextG10>Buy a pass</TextG10>
      //       </Button>
      //     ),
      //   }
      null;

  if (ticket && !pass) {
    return passElement ? [passElement, pointsElement, ...array] : [pointsElement, ...array];
  }

  return array;
};