import {Box, Button, Center, Container, Input, useTheme} from '@chakra-ui/react';
import React, {ChangeEvent, MutableRefObject, useState, useRef} from 'react';
import ProfilePicModal from '../../../Profile/Edit/ProfilePhotoModal';
import UserAvatar from '../../../../components/UserAvatar';
import styled, {css} from 'styled-components';
import useAuth from '../../../../hooks/useAuth';
import Icon from '../../../../components/Icon';
import {TextG16} from '../../../../components/typography';
import {useNavigate} from 'react-router-dom';
import {BlueButton} from '../../../../components/button';
import Header from '../Header';

const EditIconContainer = styled(Box)`
  background: white;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 5px;
  position: absolute;
  right: 5%;
  bottom: 4%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
  ${(props) =>
    props.theme &&
    props.theme.colors &&
    css`
      border: 1px solid ${props.theme.colors.grey};
    `};
`;

const iconStyle: React.CSSProperties = {
  display: 'block',
  marginTop: '2px',
  textAlign: 'center',
  fontSize: '20px',
};

const RegistrationAvatar = () => {
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<ChangeEvent<HTMLInputElement>>();
  const {user} = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const profilePhotoUrl = user?.profilePhotoUrl;
  const inputElement = useRef<HTMLInputElement | null>(null);

  const handleUpload = (upload) => setUploadedImage(upload);

  return (
    <Container px={6}>
      <Header
        title={`Upload A Photo`}
        subtitle={`Upload your own pic or a fun profile photo. If you want to skip this for now, others will just see your first and last initials.`}
      />
      <Box
        mx={4}
        mb={7}
        mt={12}
        position="relative"
        onClick={() => {
          if (!profilePhotoUrl && inputElement && inputElement.current) {
            inputElement.current.click();
          } else setShowPhotoModal(true);
        }}>
        <Center>
          <Box w={48} h={48} pos="relative">
            <UserAvatar size="full" user={user} fontSize="10rem !important" />
            <EditIconContainer theme={theme}>
              <Icon style={iconStyle} iconName="fi-rr-camera" />
            </EditIconContainer>
          </Box>
        </Center>
      </Box>
      <ProfilePicModal
        show={showPhotoModal}
        setShow={setShowPhotoModal}
        uploadedImage={uploadedImage}
      />
      <Center mb={12}>
        <Button style={{background: 'transparent', color: theme.colors.blue}}>
          <label htmlFor="image-file">
            <Input
              ref={inputElement}
              type="file"
              id="image-file"
              accept="image/*;capture=camera"
              onChange={handleUpload}
              hidden
            />
            <TextG16 style={{fontWeight: '400'}}>Upload a photo</TextG16>
          </label>
        </Button>
      </Center>
      <Center>
        <BlueButton w="60%" onClick={() => navigate('/register/welcome')}>
          Continue
        </BlueButton>
      </Center>
    </Container>
  );
};

export default RegistrationAvatar;
