import dayjs from 'dayjs';
import {Event} from '../types/acrticle';
import {useState, useEffect} from 'react';

export const useCountdown = (event: Event) => {
  const startTime = dayjs.utc(event?.start_date_time);
  const endTime = dayjs.utc(event?.end_date_time);

  const [startPassed, setStartPassed] = useState(dayjs().utc().isAfter(startTime));
  const [endPassed, setEndPassed] = useState(dayjs().utc().isAfter(endTime));
  const [canCheckIn, setCanCheckIn] = useState(dayjs().utc().isAfter(startTime));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCanCheckIn(
        event.allow_early_check_in ? dayjs.utc().add(15, 'minutes').isAfter(startTime) : startPassed
      );
    }, 1000);

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    setStartPassed(dayjs().utc().isAfter(startTime));
    setEndPassed(dayjs().utc().isAfter(endTime));
  }, [event]);

  return {startTime, endTime, startPassed, setStartPassed, endPassed, setEndPassed, canCheckIn};
};

export default useCountdown;
