import React, {useEffect, useMemo, useRef} from 'react';
import {Box, Center, Flex, HStack, Spacer, Stack, useTheme, Wrap, WrapItem} from '@chakra-ui/react';
import dayjs from 'dayjs';
import Card from '../../Card';
import {TextG10, TextG12, TextG14} from '../../typography';
import {generatePath, Link, useLocation} from 'react-router-dom';
import {ROUTES} from '../../../constants/routes';
import {Post} from '../../../types/acrticle';
import styled from 'styled-components';
import ArticleSocialIcon from '../ArticleSocialIcon';
import CardInterestTags from '../CardInterestTags';
import {useTrackVisibility} from 'react-intersection-observer-hook';
import useVisibleArticles from '../../../hooks/useVisible';
import useAuth from '../../../hooks/useAuth';
import PremiumPostCard from './PremiumPostCard';
import NonPremiumPostCard from './NonPremiumPostCard';
import {storeScrollPositionInSession} from '../../../utils/scroll';

const NoWrapText = styled(TextG10)`
  word-wrap: break-word !important;
`;

const PostCard = ({
  post,
  leftTopItem,
  rightTopItem,
  showTitle,
  height,
  fullCard = true,
  modal,
  borderRadius = '12px',
  isFeatured = false,
  onClick,
}: {
  post?: Post;
  leftTopItem?: any;
  rightTopItem?: any;
  showTitle?: boolean;
  height?: string;
  fullCard?: boolean;
  modal?: boolean;
  borderRadius?: string | undefined;
  isFeatured?: boolean;
  onClick?: () => void;
}) => {
  if (!post) return null;
  const theme = useTheme();
  const cardChildContent = useRef<HTMLDivElement>(null);
  const {user} = useAuth();
  const [ref, {wasEverVisible}] = useTrackVisibility();
  const {addVisibleArticle} = useVisibleArticles();
  const {pathname} = useLocation();

  useEffect(() => {
    if (wasEverVisible && post?.id.toString()) {
      addVisibleArticle({id: post.id.toString(), type: 'post'});
    }
  }, [wasEverVisible]);

  const image = useMemo(() => post?.image_url, [post]);

  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: function (number, withoutSuffix) {
        return withoutSuffix ? 'now' : 'a few seconds';
      },
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: '1mth',
      MM: '%dmth',
      y: '1y',
      yy: '%dy',
    },
  });

  if (!isFeatured) {
    if (post.is_premium)
      return (
        <PremiumPostCard
          onClick={onClick}
          post={post}
          leftTopItem={leftTopItem}
          rightTopItem={rightTopItem}
          isSlide={false}
          modal={modal}
        />
      );
    else
      return (
        <NonPremiumPostCard
          onClick={onClick}
          post={post}
          leftTopItem={leftTopItem}
          rightTopItem={rightTopItem}
          isSlide={false}
          modal={modal}
        />
      );
  }

  return (
    <Link
      style={{width: '100%'}}
      to={
        modal
          ? {
              pathname,
              search: `?id=${post.id.toString()}&articleType=${
                post.type ? post.type.toLowerCase() : 'post'
              }`,
            }
          : generatePath(ROUTES.POST, {
              postId: post.id.toString(),
            })
      }
      onClick={(e) => {
        if (onClick) {
          onClick();
          return;
        }

        storeScrollPositionInSession();
        e.stopPropagation();
      }}
      ref={ref}>
      <Card
        article={post}
        overlayBackgroundColor={isFeatured ? '#2729295C' : ''}
        overlayHeight={
          isFeatured && cardChildContent?.current?.clientHeight
            ? `${cardChildContent.current.clientHeight + 24}px`
            : ''
        }
        borderRadius={borderRadius}
        childPaddingX={!fullCard ? 3 : 4}
        coverImage={image}
        leftTopItem={leftTopItem}
        rightTopItem={rightTopItem}
        height={height}>
        <Box ref={cardChildContent}>
          {fullCard && post?.social_account && (
            <Wrap pb={2} align="center">
              <WrapItem>
                <ArticleSocialIcon
                  width={'16px'}
                  height={'16px'}
                  social={post?.social_account?.social_network.name}
                />
              </WrapItem>
              <WrapItem alignItems={'center'}>
                <TextG10 noOfLines={1} maxWidth={fullCard ? '' : '67px'} color={theme.colors.white}>
                  {post?.social_account?.username ? `@${post.social_account.username}` : '@rss'}
                </TextG10>
              </WrapItem>
            </Wrap>
          )}
          <Stack gap={2}>
            {isFeatured && post?.released_at && (
              <NoWrapText color={theme.colors.white}>
                {dayjs.utc(post?.released_at).fromNow(true)}
              </NoWrapText>
            )}
            <TextG14
              mt={'0 !important'}
              isTruncated="&hellip;"
              noOfLines={fullCard ? 2 : ''}
              mb={1}
              color={theme.colors.white}
              dangerouslySetInnerHTML={{__html: post.title || post.description}}
              children={undefined}
            />
          </Stack>
          <Flex justifyContent="flex-end">
            {!isFeatured && post.topics_of_interest && post.topics_of_interest.length > 0 && (
              <>
                <CardInterestTags
                  autoScroll={true}
                  tagsVisible={true}
                  onlyShowMatched={!fullCard}
                  topicsOfInterest={post.topics_of_interest}
                />
                <Spacer />
              </>
            )}
            {!isFeatured && post?.released_at && (
              <NoWrapText color={theme.colors.white}>
                {dayjs.utc(post?.released_at).fromNow(true)}
              </NoWrapText>
            )}
          </Flex>
        </Box>
      </Card>
    </Link>
  );
};

PostCard.defaultProps = {
  showTitle: true,
};

export default PostCard;
