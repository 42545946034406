import React, {useState} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import Home from '../pages/Home';
import TopBar from '../components/TopBar';
import NavigationBar from '../components/NavigationBar';
import Profile from '../pages/Profile';
import EditProfile from '../pages/Profile/Edit';
import Bookmarks from '../pages/Bookmarks';
import Place from '../pages/Place';
import Post from '../pages/Post';
import Event from '../pages/Event';
import CheckIn from '../pages/CheckIn';
import {ROUTES} from '../constants/routes';
import LifestyleGoalsAndInterests from '../pages/Profile/LifestyleGoalsAndInterests';
import {Box} from '@chakra-ui/react';
import PointsAndRewards, {PointsAndRewardsTopBar} from '../pages/Profile/PointsAndRewards';
import useAuth from '../hooks/useAuth';
import Help from '../pages/Help';
import Notifications from '../pages/Notifications';
import Tabs from '../pages/Tabs/index';
import Tab from '../pages/Tabs/Tab/index';
import LifestyleGoals from '../pages/Auth/Register/LifestyleGoals';
import Topics from '../pages/Auth/Register/Topics';
import Welcome from '../pages/Auth/Register/Welcome';
import Explainer from '../pages/Auth/Register/Explainer';
import ResetPassword from '../pages/Auth/ResetPassword';
import FilterPlaces from '../pages/FilterPlaces';
import AppRedirect from '../pages/DownloadApp/Redirect';
import Search from '../pages/Search';
import {AnimatePresence, motion} from 'framer-motion';
import ErrorBoundary from '../components/ErrorBoundary';
import Wallet from '../pages/Wallet';
import EventChat from '../components/Chat/EventChat';
import CommunityChat from '../components/Chat/CommunityChat';
import EventCommentThread from '../components/Chat/CommentThread/EventCommentThread';
import CommunityGuidelines from '../pages/CommunityGuidelines';
import RegistrationAvatar from '../pages/Auth/Register/Avatar';
import Username from '../pages/Auth/Register/Username';
import Chats from '../pages/Chats';
import MyStuff from '../pages/MyStuff';
import GoalsAndInterests from '../pages/Auth/Register/GoalsAndInterests';
import Community from '../pages/Community';
import CommunityCommentThread from '../components/Chat/CommentThread/CommunityCommentThread';
import EventPreview from '../pages/EventPreview';
import DesktopDownload from '../pages/DownloadApp/DesktopDownload';
import Partner from '../pages/Partner';
import CreateMeetup from '../pages/CreateMeetup';
import Meetup from '../pages/Meetup';

import TicketSelect from '../pages/TicketSelect';
import CheckoutPage from '../pages/CheckoutPage';
import LifestyleChat from '../components/Chat/LifestyleChat';
import Membership from '../pages/Membership';
import Memberships from '../pages/Memberships';
import PassPurchase from '../pages/PassPurchase';
import PassPage from '../pages/MyPasses/PassPage';
import TicketPage from '../pages/MyTickets/TicketPage/TicketPage';
import PlacePreview from '../pages/Place/Preview';
import PublicEvents from '../pages/PublicEvents';
import PostPreview from '../pages/Post/Preview';
import PartnerPreview from '../pages/Partner/Preview';

type WrapperProps = {
  Element: (prop) => JSX.Element;
  CustomTopBar?: (prop) => JSX.Element;
  hideTopBar?: boolean;
  hidePoints?: boolean;
  hideNavigationBar?: boolean;
  topBarProps?: Record<string, string | number | boolean>;
  progressBarProps?: Record<string, string | number | boolean>;
  navigationBarProps?: Record<string, string | number | boolean>;
  onClose?: () => void;
};
export const TRANSITION_DELAY = 0; //0.3;

export const Wrapper = ({
  Element,
  CustomTopBar,
  hideTopBar,
  hidePoints,
  hideNavigationBar,
  topBarProps,
  progressBarProps,
  navigationBarProps,
  onClose,
}: WrapperProps) => {
  const {user} = useAuth();
  const [placeName, setPlaceName] = useState('');
  return (
    <>
      {!hideTopBar &&
        (!CustomTopBar ? (
          <TopBar onClose={onClose} hidePoints={hidePoints} />
        ) : (
          <CustomTopBar onClose={onClose} />
        ))}
      <ErrorBoundary>
        <Element onClose={onClose} assignPlaceName={(name) => setPlaceName(name)} />
      </ErrorBoundary>
      {!hideNavigationBar && (
        <Box pt={16}>
          <NavigationBar placeName={placeName} {...navigationBarProps} />
        </Box>
      )}
    </>
  );
};

export const AnimateContent = (props) => {
  return (
    <motion.div
      initial={{position: 'absolute', right: '-100%'}}
      animate={{position: 'relative', right: 0}}
      exit={{opacity: 0, right: 0}}
      transition={{delay: TRANSITION_DELAY}}>
      {props.children}
    </motion.div>
  );
};

const AuthorizedRoutes = () => {
  const {user} = useAuth();
  return (
    <AnimatePresence>
      <Routes>
        <Route path="/download" element={<DesktopDownload />} />
        <Route path="/download-app" element={<AppRedirect />} />
        <Route path="/help" element={<Wrapper Element={Help} hidePoints={true} />} />
        <Route path="/register/explainer" element={<Explainer />} />
        <Route path="/register/lifestyle-goals" element={<LifestyleGoals />} />
        <Route path="/register/goals-interests" element={<GoalsAndInterests />} />
        <Route path="/register/topics" element={<Topics />} />
        <Route path="/register/welcome" element={<Welcome />} />
        <Route path="/register/profile-pic" element={<RegistrationAvatar />} />
        <Route path="/register/username" element={<Username />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/home" element={<Wrapper Element={Home} />} />
        <Route path={ROUTES.TABS} element={<Wrapper Element={Tabs} />}>
          <Route index element={<Navigate to="/home" />} />
          <Route path={ROUTES.TAB} element={<Tab />} />
          <Route path="*" element={<Navigate to="/home" />} />
        </Route>
        <Route path="/bookmarks" element={<Wrapper Element={Bookmarks} />} />
        <Route path="/wallet" element={<Wrapper Element={Wallet} />} />
        <Route path="/my-stuff" element={<Wrapper Element={MyStuff} hideTopBar={true} />} />
        <Route path="check-in" element={<CheckIn />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/edit/:id" element={<EditProfile />} />
        <Route path="/place/filter/:category" element={<Wrapper Element={FilterPlaces} />} />
        <Route
          path="/profile/points-and-rewards"
          element={
            <Wrapper
              Element={PointsAndRewards}
              progressBarProps={{hideHeader: true}}
              CustomTopBar={PointsAndRewardsTopBar}
            />
          }
        />
        <Route
          path={ROUTES.PLACE}
          element={<Wrapper Element={Place} hidePoints={true} hideTopBar={true} />}
        />
        <Route
          path="/place/:uuid/preview"
          element={
            <Wrapper
              Element={PlacePreview}
              hidePoints={true}
              hideTopBar={true}
              hideNavigationBar={true}
            />
          }
        />
        <Route
          path={ROUTES.POST}
          element={<Wrapper Element={Post} hidePoints={true} hideTopBar={true} />}
        />
        <Route
          path={'/post/:postId/preview'}
          element={
            <Wrapper
              Element={PostPreview}
              hidePoints={true}
              hideTopBar={true}
              hideNavigationBar={true}
            />
          }
        />
        <Route
          path={'/partner/:partnerId/preview'}
          element={
            <Wrapper
              Element={PartnerPreview}
              hidePoints={true}
              hideTopBar={true}
              hideNavigationBar={true}
            />
          }
        />
        <Route
          path={ROUTES.EVENT}
          element={<Wrapper Element={Event} hidePoints={true} hideTopBar={true} />}
        />
        <Route
          path={ROUTES.EVENT2}
          element={<Wrapper Element={Event} hidePoints={true} hideTopBar={true} />}
        />
        <Route path="/event/:eventId/tickets" element={<TicketSelect />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route
          path="/event/:eventId/preview"
          element={
            <Wrapper
              Element={EventPreview}
              hideNavigationBar={true}
              hidePoints={true}
              hideTopBar={true}
            />
          }
        />
        <Route
          path="/communities/:communityId"
          element={<Wrapper Element={Community} hidePoints={true} hideTopBar={true} />}
        />
        <Route
          path="/lifestyle/chat"
          element={
            <Wrapper
              Element={LifestyleChat}
              hidePoints={true}
              hideTopBar={true}
              hideNavigationBar={true}
            />
          }
        />
        <Route
          path="/event/:eventId/chat"
          element={
            <Wrapper
              Element={EventChat}
              hidePoints={true}
              hideTopBar={true}
              hideNavigationBar={true}
            />
          }
        />
        <Route
          path="/event/:eventId/chat/:commentId"
          element={
            <Wrapper
              Element={EventCommentThread}
              hidePoints={true}
              hideTopBar={true}
              hideNavigationBar={true}
            />
          }
        />
        <Route
          path="/communities/:communityId/chat"
          element={
            <Wrapper
              Element={CommunityChat}
              hidePoints={true}
              hideTopBar={true}
              hideNavigationBar={true}
            />
          }
        />
        <Route
          path="/communities/:communityId/chat/:commentId"
          element={
            <Wrapper
              Element={CommunityCommentThread}
              hidePoints={true}
              hideTopBar={true}
              hideNavigationBar={true}
            />
          }
        />
        <Route
          path="/community-guidelines"
          element={
            <Wrapper
              Element={CommunityGuidelines}
              hidePoints={true}
              hideTopBar={true}
              hideNavigationBar={true}
            />
          }
        />
        <Route
          path="/profile/lifestyle-goals-and-interests"
          element={<LifestyleGoalsAndInterests />}
        />
        <Route
          path="/notifications"
          element={<Wrapper Element={Notifications} hidePoints={true} hideTopBar={true} />}
        />
        <Route path="/register" element={<Navigate to="/register/lifestyle-goals" />} />
        <Route path={ROUTES.SEARCH} element={<Wrapper Element={Search} />} />
        <Route path={ROUTES.FEATURED_CONTENT} element={<Wrapper Element={Search} />} />
        <Route path={ROUTES.STANDALONE_SEARCH} element={<Wrapper Element={Search} />} />
        <Route path="/chats" element={<Wrapper Element={Chats} hideTopBar={true} />} />
        <Route path={ROUTES.PARTNER} element={<Wrapper Element={Partner} hideTopBar={true} />} />
        <Route
          path="/create-meetup"
          element={<Wrapper Element={CreateMeetup} hideTopBar={true} hideNavigationBar={true} />}
        />
        <Route
          path={ROUTES.MEET_UP}
          element={<Wrapper Element={Meetup} hideTopBar={true} hideNavigationBar={true} />}
        />
        <Route
          path={ROUTES.MEET_UP2}
          element={<Wrapper Element={Meetup} hideTopBar={true} hideNavigationBar={true} />}
        />
        <Route path="/memberships" element={<Wrapper Element={Memberships} hideTopBar={true} />} />
        <Route
          path="/membership/:membershipId"
          element={<Wrapper Element={Membership} hideTopBar={true} />}
        />
        <Route path="/pass/:passId" element={<PassPage />} />
        <Route path="/pass/:passId/purchase" element={<PassPurchase />} />
        <Route path="/tickets/:eventId" element={<TicketPage />} />
        <Route path="/public-events" element={<PublicEvents />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AuthorizedRoutes;
