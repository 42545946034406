import {Box, useTheme, VStack} from '@chakra-ui/react';
import React, {useState} from 'react';
import {TextA18, TextG12, TextG14} from '../../../components/typography';
import PaymentOptions from '../PaymentOptions';
import Header from './Header';
import OrderDetails from '../OrderDetails';
import {BlueButton} from '../../../components/button';
import {useSelector} from 'react-redux';
import {numberToCurrency} from '../../../utils/price';

const CheckoutDetails = ({setShowSummary, ticket, pass, event, userPasses}) => {
  const theme = useTheme();
  const [highlightedOptionId, setHighlightedOptionId] = useState<string | undefined>();
  const {amountDue, originalTotal} = useSelector((state: any) => state.checkout);

  return (
    <>
      <Header pass={pass} event={event} />
      <OrderDetails ticket={ticket} pass={pass} />
      <VStack align="flex-start" px={4} py={6}>
        <VStack align="flex-start" w="100%">
          <TextA18
            transition="all ease 0.3s"
            fontWeight="700"
            color={amountDue > 0 ? theme.colors.red : theme.colors.darkGrey}>
            {`Total Amount Due: ${numberToCurrency(amountDue)}`}
          </TextA18>
          <TextG12 marginTop="0px !important">
            select which method you'd like to use to check out
          </TextG12>
          <PaymentOptions
            pass={pass}
            ticket={ticket}
            highlightedOptionId={highlightedOptionId}
            setHighlightedOptionId={setHighlightedOptionId}
            event={event}
            userPasses={userPasses}
          />
        </VStack>
      </VStack>
      <Box
        onClick={() => {
          if (amountDue <= 0) {
            setShowSummary(true);
          }
        }}
        w="100%"
        p={4}
        pos="fixed"
        bottom="0"
        background="white">
        <BlueButton isDisabled={amountDue > 0} w="100%">
          <TextG14>Continue</TextG14>
        </BlueButton>
      </Box>
    </>
  );
};

export default CheckoutDetails;
