import {useCallback, useEffect, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import {fetchGeneralSettings} from '../endpoints/api';
import {find} from 'lodash';

export const useGetGeneralSettings = (page: string = '1') => {
  return useQuery(['generalSettings', page], fetchGeneralSettings, {staleTime: (60 * 60 * 1000)});//1 hour
};

export const useGeneralSettings = () => {
  const [settings, setSettings] = useState<Array<any>>([]);
  const getSettings = useGetGeneralSettings();

  useEffect(() => {
    if (getSettings.data?.data.data) {
      setSettings(getSettings.data.data.data);
    }
  }, [getSettings.data?.data]);

  const getSetting = useCallback(
    (setting) => {
      return find(settings, {key: setting})?.value;
    },
    [settings]
  );

  return {settings, getSetting, ...getSettings};
};
