import {Box, Flex, useTheme} from '@chakra-ui/react';
import React, {useState} from 'react';
import {TextG12} from '../../components/typography';
import useAuth from '../../hooks/useAuth';
import {numberToCurrency} from '../../utils/price';

export const MAX_TICKET_QTY = 4;

const TicketQuantityContainer = ({ticket, quantity, setQuantity}) => {
  const theme = useTheme();

  const handleChange = (e) => {
    setQuantity(parseInt(e.target.value));
  };
  const {user} = useAuth();
  const total = parseFloat(ticket.price.replace(/[^\d.-]/g, '')) * quantity;
  const discountPerc = user?.activeMembershipPlan?.event_discount || 0;

  return (
    <Flex
      align="center"
      justifyContent="space-between"
      py={3}
      px={4}
      w="100%"
      margin="0px !important"
      background={theme.colors.veryLightBlue}>
      <Box borderRadius="8px" p={3} background="white" border={`1px solid ${theme.colors.grey}`}>
        <TextG12 fontWeight="500">
          <label style={{marginRight: '6px'}} htmlFor="quantity">
            Tickets:
          </label>
          <select
            style={{fontWeight: '500'}}
            id="quantity"
            value={quantity}
            onChange={handleChange}>
            {[...Array(MAX_TICKET_QTY).keys()].map((num) => (
              <option key={num + 1} value={num + 1}>
                {num < 9 ? `0` : ``}
                {num + 1}
              </option>
            ))}
          </select>
        </TextG12>
      </Box>

      <Box borderRadius="8px" p={3} background="white" border={`1px solid ${theme.colors.grey}`}>
        <TextG12 fontWeight="500">
          <span style={{marginRight: '6px'}}>Total:</span>
          <span>
            {discountPerc && discountPerc > 0
              ? numberToCurrency(total - (total * discountPerc) / 100)
              : numberToCurrency(total)}
          </span>
        </TextG12>
      </Box>
    </Flex>
  );
};

export default TicketQuantityContainer;
