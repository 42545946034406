import React, {useState} from 'react';
import {Image, Center, VStack, Box, useTheme, Container, HStack, Flex} from '@chakra-ui/react';
import {H2, TextG14, TextA18, TextG12, TextG10} from '../../../../components/typography';
import {BlueButton, BlueButtonFixedWidth, WhiteButton} from '../../../../components/button';
import {Checkbox} from '../../../../components/checkbox';
import {useNavigate} from 'react-router-dom';
import useAuth from '../../../../hooks/useAuth';
import {updateUser, completeRegistration} from '../../../../endpoints/api';
import {PageLoader} from '../../../../components/PageLoader';
import {getAppName} from '../../../../utils/app';
import {ToastMessage} from '../../../../components/toast';
import WelcomeImage from '../../../../assets/images/welcome-image.png';
import Logo from '../../../../assets/logos/logo_120x120.png';
import {
  PointsDistributionTypes,
  usePointsDistribution,
} from '../../../../hooks/usePointsDistribution';
import {isMobile, isStandalone} from '../../../../utils/deviceCheck';
import RequiresEarlyAccess from '../../../../components/RequiresEarlyAccess';

const Welcome = () => {
  const {user, reFetchUserData} = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const {getPointAmount} = usePointsDistribution();
  const welcomePoints = getPointAmount(PointsDistributionTypes.REGISTRATION_COMPLETED);

  const handleOnContinueClick = () => {
    updateUser({sms_enabled: checked}).then(() => {
      setIsLoading(true);
      completeRegistration()
        .then(() =>
          reFetchUserData()
            .catch((e) =>
              ToastMessage({
                status: 'error',
                text: e.message,
              })
            )
            .finally(() =>
              isMobile || window['ReactNativeWebView']
                ? navigate('/home')
                : navigate('/download')
            )
        )
        .catch((e) => console.log(e))
        .finally(() =>
          isMobile || window['ReactNativeWebView']
            ? navigate('/home')
            : navigate('/download')
        );
    });
  };

  const handleOnMembershipsClick = () => {
    updateUser({sms_enabled: checked}).then(() => {
      setIsLoading(true);
      completeRegistration()
        .then(() =>
          reFetchUserData()
            .catch((e) =>
              ToastMessage({
                status: 'error',
                text: e.message,
              })
            )
            .finally(() =>
              isMobile || window['ReactNativeWebView']
                ? navigate('/memberships')
                : navigate('/download')
            )
        )
        .catch((e) => console.log(e))
        .finally(() =>
          isMobile || window['ReactNativeWebView']
            ? navigate('/memberships')
            : navigate('/download')
        );
    });
  };

  if (isLoading) return <PageLoader />;

  if (!user) {
    return <TextG14>Something went wrong</TextG14>;
  }

  return (
    <Container px={8}>
      <VStack px={5} spacing={3} mb={5} mt={5}>
        <Center my={5}>
          <Image px="150px" h={'100%'} paddingInline={0} src={Logo} width="61px" alt="Logo" />
        </Center>
        <H2>{`You Did It, ${user.name}!`}</H2>
      </VStack>
      <VStack spacing="15px">
        <Center>
          <Image h={'100%'} src={WelcomeImage} width="100%" alt="Welcome" />
        </Center>
      </VStack>
      <TextG14 color={theme.colors.grey} mt="40px" mb="30px" lineHeight="19.95px">
        Awesome, you’re in with a{' '}
        <b style={{color: theme.colors.rose}}>free Walkabout membership!</b> Here’s what’s coming
        your way:
        <ul style={{marginLeft: '20px', marginTop: '25px'}}>
          <li>Invitations to mixers with other locals</li>
          <li>Sweet perks at neighborhood businesses</li>
          <li>Fun opportunities to connect and learn about your community</li>
        </ul>
      </TextG14>
      <Center>
        <Flex flexDir="column" gap="20px">
          <RequiresEarlyAccess>
            <BlueButton w="223px" onClick={handleOnMembershipsClick}>
              <TextG12>View Premium Memberships</TextG12>
            </BlueButton>
          </RequiresEarlyAccess>
          <WhiteButton
            w="223px"
            border={`1px solid ${theme.colors.blue}`}
            onClick={handleOnContinueClick}>
            Continue to App
          </WhiteButton>
        </Flex>
      </Center>
      <Center>
        <HStack spacing={2} py="20px" alignItems="flex-end">
          <Box>
            <Checkbox
              isCircle={false}
              onChange={() => setChecked(!checked)}
              checked={checked}
              disabled={false}
              styled
            />
          </Box>
          <TextG14 color={theme.colors.lightBlack} fontWeight="500">
            Send me SMS
          </TextG14>
        </HStack>
      </Center>
      <Center>
        <TextG12 color={theme.colors.grey} textAlign="center" lineHeight="17.1px">
          We send you personalized invites and perks — you can opt out at any time.
        </TextG12>
      </Center>
    </Container>
  );
};

export default Welcome;
