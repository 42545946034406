import {Container, Flex, Center, Spacer, Divider, useTheme, AbsoluteCenter} from '@chakra-ui/react';
import {filter, groupBy, has, map} from 'lodash';
import React from 'react';
import {H3, TextA18, TextG14} from '../../components/typography';
import {useFetchUserTickets} from '../../hooks/useUserTickets';
import TicketCard from './TicketCard';

const MyTickets = () => {
  const theme = useTheme();
  const {data, isLoading} = useFetchUserTickets();
  const userTickets = groupBy(
    filter(data?.data, (item) => has(item, 'deleted_at') && item.deleted_at == null),
    (item) => item?.event?.id
  );

  if (isLoading) {
    return null;
  }

  return (
    <Container pt={1} px={'0 !important'} pb={'initial'} h="auto">
      <Flex my={3}>
        <Center>
          <H3 color={theme.colors.darkGrey}>Tickets</H3>
        </Center>
        <Spacer />
      </Flex>
      <Divider mb={3} />
      {userTickets && data?.data.length > 0 ? (
        <Flex flexDir="column" gap="16px">
          {map(userTickets, (tickets) => {
            const ticket = tickets[0];
            return <TicketCard key={ticket.uuid} ticket={ticket} numTickets={tickets.length} />;
          })}
        </Flex>
      ) : (
        <AbsoluteCenter textAlign="center" w="100%">
          <TextA18>You don't have any tickets yet!</TextA18>
        </AbsoluteCenter>
      )}
    </Container>
  );
};

export default MyTickets;
