import {Box, Flex, Progress, useTheme} from '@chakra-ui/react';
import React from 'react';
import BottomModalSheet from '../Modals/BottomModalSheet';
import {TextG16, TextG12, TextG10} from '../typography';
import {deviceDetected} from '../../utils/deviceCheck';
import BottomSheetOption from './BottomSheetOption';
import JourneyBadge from './JouryneyBadge';
import {sortBy} from 'lodash';

type GuidedBottomSheetProps = {
  taskList: Array<any>;
  numCompleted: number;
  openModal: boolean;
  onModalClose: () => void;
  onClick: (option: any) => void;
};

const GuidedBottomSheet = ({
                             taskList,
                             numCompleted,
                             onModalClose,
                             openModal,
                             onClick,
                           }: GuidedBottomSheetProps) => {
  const device = deviceDetected();
  const sortedTasks = sortBy(taskList, 'position');
  const percCompleted = (numCompleted / sortedTasks.length) * 100;

  return (
      <BottomModalSheet openModal={openModal} onModalClose={onModalClose} disableDrag={true}>
        <Box
            maxHeight="90vh"
            overflow="auto"
            px={4}
            pb={device === 'iphone' || device === 'iphone-pwa' ? '14px' : 0}>
          <Flex flexDir="column">
            <Flex justifyContent="space-between">
              <Box>
                <TextG16 fontWeight="700" mb="10px">
                  Get Plugged-In
                </TextG16>
                <Flex gap="4px">
                  <TextG12 fontWeight="700">{`${numCompleted}/${sortedTasks.length}`}</TextG12>
                  <TextG12>Videos completed</TextG12>
                </Flex>
              </Box>
              {/* <JourneyBadge w="46px" h="46px" /> */}
            </Flex>

            <Box pos="relative" mb="30px">
              <Progress
                  mt={4}
                  mb={3}
                  colorScheme="purpleProgress"
                  w="100%"
                  value={numCompleted}
                  max={sortedTasks.length}
                  borderRadius="6px"
                  h="8px"
              />
              <Box
                  borderRadius="4px"
                  pos="absolute"
                  h="14px"
                  w="4px"
                  background="#A87FFF"
                  top="13px"
                  left={`${Math.max(percCompleted - 1)}% !important`}></Box>
              <Box
                  whiteSpace="nowrap"
                  pos="absolute"
                  left={`${Math.max(percCompleted - (percCompleted > 90 ? 5 : 3))}% !important`}>
                <TextG10 fontWeight="700" color="#A87FFF">{`${(
                    (numCompleted / sortedTasks.length) *
                    100
                ).toFixed(0)}%`}</TextG10>
              </Box>
              {percCompleted > 15 && (
                  <Box pos="absolute" left={`0% !important`} mb={2}>
                    <TextG10 color="black">0%</TextG10>
                  </Box>
              )}

              {percCompleted < 90 && (
                  <Box pos="absolute" left={`90% !important`} mb={2}>
                    <TextG10 color="black">100%</TextG10>
                  </Box>
              )}
            </Box>

            {sortedTasks.map((option, i) => (
                <BottomSheetOption key={i} index={i} option={option} onClick={onClick} />
            ))}
          </Flex>
        </Box>
      </BottomModalSheet>
  );
};

export default GuidedBottomSheet;
