import {Flex, useTheme} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import Icon from '../../../components/Icon';
import {TextA18} from '../../../components/typography';

const Header = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Flex background="white" px={3} pt={5} align="flex-start" w="100%" pos="relative">
      <TextA18 textAlign="center" fontWeight="700" w="100%">
        Tickets
      </TextA18>
      <Icon
        onClick={() => navigate('/my-stuff?tab=Tickets')}
        iconName="fi-br-cross"
        style={{fontSize: '19px', position: 'absolute', top: '20px', right: '20px'}}
        height="19px"
        width="19px"
      />
    </Flex>
  );
};

export default Header;
