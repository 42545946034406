import {Center, Flex, Link, Stack} from '@chakra-ui/react';
import React from 'react';
import {Partner} from '../../../types/acrticle';
import {TextA18, TextG12, TextG14} from '../../../components/typography';
import {BlueButton} from '../../../components/button';
import SwiperSlider from '../../../components/SwiperSlider';
import PlaceCard from '../../../components/Articles/PlaceCard';
import ArticleInfo from '../../../components/Articles/ArticleInfo';
import {BookmarkElement} from '../../../components/Articles';
import ExternalLink from '../../../components/ExternalLink';

const About: React.FC<{partner: Partner}> = (props) => {
  return (
    <>
      <Stack gap=".25rem" textAlign="start" p="1rem">
        {props.partner.website && (
          <Center>
            <ExternalLink
              w="90%"
              href={props.partner.website}
              isExternal
              style={{textDecoration: 'none'}}>
              <BlueButton w="100%">
                <TextG14>Website</TextG14>
              </BlueButton>
            </ExternalLink>
          </Center>
        )}

        <Flex px="5%" pt="5%" flexDir="column" gap="10px">
          <TextG12 dangerouslySetInnerHTML={{__html: props.partner.description}}>{}</TextG12>
          <TextG12 dangerouslySetInnerHTML={{__html: props.partner.content}}>{}</TextG12>
        </Flex>
        {props.partner.places && props.partner.places.length > 0 && (
          <>
            <TextA18>Visit This Place</TextA18>
            <SwiperSlider
              items={props.partner.places}
              render={(item) => (
                <PlaceCard
                  place={item}
                  leftTopItem={<ArticleInfo article={item} />}
                  rightTopItem={<BookmarkElement article={item} />}
                />
              )}
            />
          </>
        )}
      </Stack>
    </>
  );
};

export default About;
