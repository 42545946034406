import {useInfiniteQuery, useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {
  addChatMedia,
  createCommunityComment,
  deleteChatMedia,
  deleteCommunityComment,
  fetchChatMedia,
  fetchCommunity,
  fetchCommunityChatUsers,
  fetchCommunityComment,
  fetchCommunityComments,
  fetchCommunityReplyMentionComments,
  fetchCommunityStripeURL,
  fetchCommunityUserNum,
  fetchFeaturedCommunityComments,
  helpfulComment,
  helpfulCommunityComment,
  lastCommunityChatVisit,
  reportComment,
  reportCommunityComment,
  updateCommunityComment,
} from '../endpoints/api';
import {User} from './useAuth';
import {tail} from 'lodash';
import {ToastMessage} from '../components/toast';
import {AxiosError} from 'axios';

const INTERVAL_MS = 5000; // 5 seconds

export const useInfiniteFeaturedCommunityComments = (
  communityId: number,
  enabled: boolean = true
) => {
  return useInfiniteQuery(
    ['infinite_featured_community_comments', communityId],
    ({pageParam = 1}) => fetchFeaturedCommunityComments({communityId, page: pageParam}),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.current_page !== lastPage.data.last_page) {
          return lastPage.data.current_page + 1;
        }
      },
      refetchInterval: INTERVAL_MS,
      enabled: enabled && typeof communityId === 'number',
    }
  );
};

export const useInfiniteCommunityComments = (communityId: number, enabled: boolean = true) => {
  return useInfiniteQuery(
    ['infinite_community_comments', communityId],
    ({pageParam = 1}) => fetchCommunityComments({communityId, page: pageParam}),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.current_page !== lastPage.data.last_page) {
          return lastPage.data.current_page + 1;
        }
      },
      refetchInterval: INTERVAL_MS,
      enabled: enabled && typeof communityId === 'number',
    }
  );
};

export const useCreateCommunityComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      communityId,
      content,
      parent_id,
      is_featured,
      user,
    }: {
      communityId: number;
      content: string;
      parent_id?: string;
      is_featured?: boolean;
      user?: User;
    }) => createCommunityComment({communityId, content, parent_id, is_featured}),
    {
      onMutate: async (newComment) => {
        if (newComment.parent_id) return;
        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({
          queryKey: ['infinite_community_comments', newComment.communityId],
        });

        // Snapshot the previous value
        const previousComments: any = queryClient.getQueryData([
          'infinite_community_comments',
          newComment.communityId,
        ]);

        const newData = {
          ...previousComments,
          pages: [
            {
              ...previousComments.pages[0],
              data: {
                ...previousComments.pages[0].data,
                data: [{id: '1', ...newComment}, ...previousComments.pages[0].data.data],
              },
            },
            ...tail(previousComments.pages),
          ],
        };

        if (previousComments && previousComments.pages[0]) {
          // Optimistically update to the new value
          queryClient.setQueryData(
            ['infinite_community_comments', newComment.communityId],
            newData
          );
        }

        // Return a context with the previous and new comment
        return {previousComments, newComment};
      },
      onSettled: (newComment, error, variables, context) => {
        if (error && error instanceof AxiosError) {
          ToastMessage({
            status: 'error',
            text: error.message,
          });
        }
        queryClient.invalidateQueries(['infinite_community_comments', variables.communityId]);
        queryClient.invalidateQueries(['community_comments', variables.communityId]);
      },
    }
  );
};

export const useUpdateCommunityComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      communityId,
      commentId,
      content,
    }: {
      communityId: number;
      commentId: string;
      content: string;
    }) => updateCommunityComment({communityId, commentId, content}),
    {
      onSettled: (newComment, error, variables, context) => {
        if (error && error instanceof AxiosError) {
          ToastMessage({
            status: 'error',
            text: error.message,
          });
        }
        queryClient.invalidateQueries(['infinite_community_comments', variables.communityId]);
        queryClient.invalidateQueries(['community_comments', variables.communityId]);
      },
    }
  );
};

export const useDeleteCommunityComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({communityId, commentId}: {communityId: number; commentId: string}) =>
      deleteCommunityComment({communityId, commentId}),
    {
      onSettled: (newComment, error, variables, context) => {
        if (error && error instanceof AxiosError) {
          ToastMessage({
            status: 'error',
            text: error.message,
          });
        }
        queryClient.invalidateQueries(['infinite_community_comments', variables.communityId]);
        queryClient.invalidateQueries(['community_comments', variables.communityId]);
      },
    }
  );
};

export const useReportCommunityComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({communityId, commentId}: {communityId: number; commentId: string}) =>
      reportCommunityComment({communityId, commentId}),
    {
      onSettled: (newComment, error, variables, context) => {
        queryClient.invalidateQueries(['infinite_community_comments', variables.communityId]);
        queryClient.invalidateQueries(['community_comments', variables.communityId]);
      },
    }
  );
};

export const useHelpfulCommunityComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({communityId, commentId}: {communityId: number; commentId: string}) =>
      helpfulCommunityComment({communityId, commentId}),
    {
      onSettled: (newComment, error, variables, context) => {
        queryClient.invalidateQueries(['infinite_community_comments', variables.communityId]);
        queryClient.invalidateQueries(['community_comments', variables.communityId]);
      },
    }
  );
};

export const useFetchCommunityChatUsers = (communityId: number) => {
  return useInfiniteQuery(
    ['infinite_community_users', communityId],
    ({pageParam = 1}) => fetchCommunityChatUsers({communityId, page: pageParam}),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.current_page !== lastPage.data.last_page) {
          return lastPage.data.current_page + 1;
        }
      },
      enabled: typeof communityId === 'number',
    }
  );
};

export function useFetchRepliesAndMentions(communityId: number, enabled: boolean = true) {
  return useQuery(
    ['community_replies', communityId],
    () => fetchCommunityReplyMentionComments({communityId}),
    {
      enabled: enabled && typeof communityId === 'number',
      // Refetch the data every INTERVAL_MS
      refetchInterval: INTERVAL_MS,
    }
  );
}

export function useFetchCommunity(communityId: number, enabled: boolean = true) {
  return useQuery(['communities', communityId], () => fetchCommunity(communityId), {
    enabled: enabled && typeof communityId === 'number',
  });
}

export function useFetchCommunityUserNum(communityId: number) {
  return useQuery(['communities_num', communityId], () => fetchCommunityUserNum(communityId), {
    enabled: typeof communityId === 'number',
  });
}

export function useFetchCommunityComment({communityId, commentId, enabled}) {
  return useQuery(
    ['community_comment', communityId, commentId],
    () => fetchCommunityComment({communityId, commentId}),
    {
      enabled: enabled && typeof communityId === 'number',
      // Refetch the data every INTERVAL_MS
      refetchInterval: INTERVAL_MS,
      retry: 1,
    }
  );
}

export function useFetchCommunityStripeURL(communityId: number, enabled: boolean = true) {
  return useQuery(['communities_stripe', communityId], () => fetchCommunityStripeURL(communityId), {
    enabled: enabled && typeof communityId === 'number',
  });
}

export const useLastChatVisit = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({communityId}: {communityId: number}) => lastCommunityChatVisit({communityId}),
    {
      onSettled: (newComment, error, variables, context) => {
        queryClient.invalidateQueries(['new_community_messages']);
      },
    }
  );
};

export const useAddCommentImage = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      formData,
      target_id,
      comment_id,
    }: {
      formData: FormData;
      target_id: string;
      comment_id: string;
    }) =>
      addChatMedia({
        formData,
        target_type: 'community',
        target_id,
        comment_type: 'community_comment',
        comment_id,
      }),
    {
      onSettled: (newComment, error, variables, context) => {
        if (error && error instanceof AxiosError) {
          ToastMessage({
            status: 'error',
            text: error.message,
          });
        }
        queryClient.invalidateQueries(['infinite_community_comments', variables.target_id]);
        queryClient.invalidateQueries(['community_comments', variables.target_id]);
      },
    }
  );
};

export const useDeleteCommentImage = () => {
  const queryClient = useQueryClient();
  return useMutation(({mediaId}: {mediaId: string}) => deleteChatMedia({mediaId}), {
    onSettled: (newComment, error, variables, context) => {
      if (error && error instanceof AxiosError) {
        ToastMessage({
          status: 'error',
          text: error.message,
        });
      }
      queryClient.invalidateQueries(['infinite_community_comments']);
      queryClient.invalidateQueries(['community_comments']);
    },
  });
};

export const useFetchCommentMedia = ({mediaId}) => {
  return useQuery(['community_comment_media', mediaId], () => fetchChatMedia({mediaId}), {
    enabled: typeof mediaId === 'number',
  });
};
