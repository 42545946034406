import React, {useState} from 'react';
import {Center, Flex, Spinner, useTheme} from '@chakra-ui/react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import BackButton from '../../components/BackButton';
import {Location} from '../../hooks/useUserLocation';
import {useFetchPlace} from '../../hooks/useFetchPlace';
import MediaGallery from '../../components/MediaGallery';
import {useFetchPartner} from '../../hooks/usePartner';
import Header from './Header';
import About from './About';
import PartnerTabs from './PartnerTabs';

const Partner = ({onClose}: {assignPlaceName?: (string) => void; onClose?: () => void}) => {
  const {partnerId} = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const fetchPartner = useFetchPartner(Number(partnerId || searchParams.get('id')));
  const partner = fetchPartner.data?.data;

  const navigateBack = React.useCallback(() => {
    if (searchParams.has('id') && onClose) {
      onClose();
      return;
    }
    navigate(-1);
  }, []);

  if (fetchPartner.isLoading || partner === undefined) {
    return (
      <Center style={centerCss}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );
  }

  return (
    <>
      <Flex position="relative" height="275px">
        <BackButton onClick={navigateBack} m={'.75rem'} pos="relative" />
        <MediaGallery media={partner.media} />
      </Flex>
      <Header partner={partner} />
      <PartnerTabs partner={partner} />
    </>
  );
};

export default Partner;

const centerCss: React.CSSProperties = {
  minHeight: '100vh',
};
