import {Flex, Image, Box, useTheme, Spacer} from '@chakra-ui/react';
import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {BlueButton} from '../../components/button';
import {TextG10, TextG14} from '../../components/typography';
import {useGeneralSettings} from '../../hooks/useGeneralSettings';
import useGetUserLocation from '../../hooks/useUserLocation';
import getPlaceAddress from '../../utils/address';
import {calculateAndGetDistance, getDistanceString} from '../../utils/distance';
import FallbackImg from '../../assets/images/fallback.jpg';
import {capitalize} from 'lodash';

const CheckinCard = ({article, handleSelect}) => {
  const theme = useTheme();
  const userLocation = useGetUserLocation();
  const navigate = useNavigate();
  const {getSetting} = useGeneralSettings();

  const type = article.model_type;
  const title = article.name || article.title;
  const address = getPlaceAddress(article?.place || article, true);

  const distance = useMemo(() => {
    if (article) {
      return calculateAndGetDistance(
        {
          latitude: Number(userLocation.data?.latitude),
          longitude: Number(userLocation.data?.longitude),
        },
        {
          latitude: Number(article.latitude),
          longitude: Number(article.longitude),
        }
      );
    }
    return 0;
  }, [userLocation.data, article]);

  return (
    <Flex
      key={article.id}
      px={4}
      py={4}
      borderBottom={`1px solid ${theme.colors.lightestGrey}`}
      onClick={() => handleSelect(article)}>
      <Image
        w={'45px'}
        h={'45px'}
        objectFit={'cover'}
        src={article.image_url}
        fallbackSrc={FallbackImg}
      />
      <Box alignSelf={'center'} px={3}>
        <TextG10 color={theme.colors.grey}>{address || '3117 W North Park Street'}</TextG10>
        <TextG14 fontWeight={700}>{title}</TextG14>
        <TextG10
          title={getDistanceString(distance)}
          alignSelf={'center'}
          fontWeight={500}
          color={theme.colors.lightestGrey}
          whiteSpace={'nowrap'}
          overflow={'hidden'}
          textOverflow={'ellipsis'}
          w={'60px'}>
          {getDistanceString(distance)}
        </TextG10>
      </Box>
      <Spacer />
      <Flex flexDir="column" gap="8px">
        {distance >= 0 && distance < getSetting('checkin_distance') && (
          <BlueButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate(`/${type}/${article.id}?action=check-in`);
            }}
            height="24px"
            fontSize="12px"
            alignSelf="center"
            minW="80px">
            Check-In
          </BlueButton>
        )}
        <Box
          bg={'white'}
          borderRadius="15px"
          border={`1px solid ${theme.colors.lighterGrey}`}
          w={'fit-content'}
          alignSelf={'end'}>
          <TextG10 fontWeight={500} color={'grey'} px={'6px'} py={'3px'}>
            {`${capitalize(type)}s`}
          </TextG10>
        </Box>
      </Flex>
    </Flex>
  );
};

export default CheckinCard;
