import {Box, Flex, Image, useTheme} from '@chakra-ui/react';
import {find} from 'lodash';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import {useFetchCommunity} from '../../hooks/useCommunityComments';
import {SkeletonLoader} from '../SkeletonLoader';
import {TextG10, TextG14} from '../typography';

const HomeCommunityButton = () => {
  const FOODIE_ID = 8;
  const {data, isLoading} = useFetchCommunity(FOODIE_ID);
  const community = data?.data;
  const theme = useTheme();
  const navigate = useNavigate();
  const {user} = useAuth();

  const isInCommunity = find(user?.communities, {id: community?.id});

  if (isLoading || !community) return <SkeletonLoader hasTitle={false} cardHeight="84px" />;

  return (
    <Box
      position="relative"
      backgroundColor={theme.colors.darkYellow}
      color="white"
      px={1}
      py={3}
      borderRadius="10px"
      onClick={() => {
        navigate(`/communities/${FOODIE_ID}`);
      }}>
      {!isInCommunity && (
        <Box
          position="absolute"
          backgroundColor={theme.colors.blue}
          color="white"
          borderRadius="10px"
          top="10px"
          right="10px"
          px={2}
          py={0.5}>
          <TextG10>New</TextG10>
        </Box>
      )}
      <Flex alignItems="center" gap="12px">
        {community.logo && (
          <Image
            w="60px"
            h="60px"
            borderRadius="100%"
            objectFit="scale-down"
            src={community.logo}
          />
        )}
        <TextG14 fontWeight="700">
          {isInCommunity ? `Your ${community.name}` : `Discover ${community.name}!`}
        </TextG14>
      </Flex>
    </Box>
  );
};

export default HomeCommunityButton;
